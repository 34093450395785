import { useRef, useState, useEffect } from "react";
import FormsNavbar from "../components/FormsNavbar";
import { Container } from "react-bootstrap";
import { getLocalData, setLocalData } from "../services/auth";
import { useNavigate } from "react-router";
import Loader from "../components/Loader";
import { useSearchParams } from "react-router-dom";
import Notification from "../components/Notification";

const OTP = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const length = 6; // Define the length of the OTP
  const [otp, setOtp] = useState(Array(length).fill(""));
  const inputs = useRef([]);
  const [timer, setTimer] = useState(30); // Countdown timer in seconds
  const [isResendEnabled, setIsResendEnabled] = useState(false); // Resend button state
  const intervalRef = useRef(null); // Ref to store the interval ID

  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [info, setInfo] = useState(JSON.parse(getLocalData("regData")));

  useEffect(() => {
    const regData = JSON.parse(getLocalData("regData"));

    if (regData === null) return navigate("/login");
  }, []);

  // Timer logic
  useEffect(() => {
    startTimer(); // Start the timer when the component mounts

    return () => {
      // Cleanup interval on unmount
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const startTimer = () => {
    // Reset the timer and disable the resend button
    setTimer(30);
    setIsResendEnabled(false);

    // Clear any existing interval before starting a new one
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    // Start a new interval
    intervalRef.current = setInterval(() => {
      setTimer((prev) => {
        if (prev > 1) {
          return prev - 1;
        } else {
          clearInterval(intervalRef.current); // Stop the timer at 0
          setIsResendEnabled(true); // Enable the resend button
          return 0;
        }
      });
    }, 1000);
  };

  const handleResendCode = async () => {
    startTimer(); // Restart the timer
    setOtp(Array(length).fill("")); // Clear the OTP inputs

    const result = await (
      await fetch(`https://bitloomchain-api.bitloomchain.com/api/v1/otp`, {
        method: "POST",

        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ email: info?.formData.email }),
      })
    ).json();

    const data = { formData: info?.formData, otpCode: result };
    setLocalData("regData", data);

    setInfo(data);
  };

  const handleChange = (e, index) => {
    const { value } = e.target;

    // Only allow single digit input
    if (/^\d$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // Move focus to the next input if not the last
      if (index < length - 1) {
        inputs.current[index + 1].focus();
      }
    } else if (value === "") {
      // Clear the input if the user deletes (empty value)
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];

      // Clear the current input
      if (otp[index] !== "") {
        newOtp[index] = "";
        setOtp(newOtp);
      } else if (index > 0) {
        // Move focus to the previous input if the current one is empty
        inputs.current[index - 1].focus();
      }
    }
  };

  const handleVerify = async () => {
    try {
      setIsLoading(true);

      if (Number(otp.join("")) !== info?.otpCode.code) {
        throw new Error("Incorrect Pin");
      } else {
        const result = await (
          await fetch(
            `https://bitloomchain-api.bitloomchain.com/api/v1/register?ref=${searchParams.get(
              "ref"
            )}`,
            {
              method: "POST",

              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(info?.formData),
            }
          )
        ).json();

        if (result.msg) {
          throw new Error("Email already exist!");
        } else {
          localStorage.removeItem("regData");
          navigate("/pending");
        }
      }
    } catch (error) {
      // console.log(error.message);
      setMessage(error.message);
      setColor("danger");
      setShowAlert(true);
      setIsLoading(false);
      localStorage.removeItem("regData");
      setTimeout(() => {
        navigate("/login"); // Change this to your target route
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FormsNavbar />

      {showAlert ? (
        <Notification
          message={message}
          color={color}
          setShowAlert={setShowAlert}
        />
      ) : (
        ""
      )}

      <Container className="signupWrapper">
        <div className="profile">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <h5 style={{ color: "#212529", fontSize: "16px" }}>
                Enter the 6-digit code sent to
              </h5>
              <h5 style={{ color: "rgb(21 153 183)", fontSize: "16px" }}>
                {/* {data?.email} */}
              </h5>

              <br />

              <h5 style={{ color: "#212529", fontSize: "16px" }}>
                It may take up to a minute for you to recieve the code.
              </h5>

              <br />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
              >
                {otp.map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    value={otp[index]}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputs.current[index] = el)}
                    style={{
                      width: "48px",
                      height: "48px",
                      margin: "0 5px",
                      textAlign: "center",
                      fontSize: "18px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  />
                ))}
              </div>
            </div>

            {/* Resend Button */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                type="submit"
                id="logBtn"
                style={{ width: 500 }}
                onClick={handleVerify}
              >
                {isLoading ? <Loader /> : "Verify"}
              </button>
            </div>
            <br />
            <br />

            {/* Timer */}
            <div
              style={{
                marginBottom: "20px",
                fontSize: "16px",
                color: timer > 0 ? "gray" : "green",
              }}
            >
              {timer > 0
                ? `Resend code in ${timer} seconds`
                : "You can now resend the code."}
            </div>

            {/* Resend Button */}
            <span
              onClick={handleResendCode}
              disabled={!isResendEnabled}
              style={{
                cursor: isResendEnabled ? "pointer" : "not-allowed",
                color: isResendEnabled ? "#007BFF" : "#ccc",
              }}
            >
              Resend Code
            </span>
            <br />
            <br />
          </div>
        </div>
      </Container>
    </>
  );
};

export default OTP;
