import LoggedInNavbar from "../components/LoggedInNavbar";
import {
  Alert,
  Col,
  Container,
  Form,
  Modal,
  Overlay,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import dollar from "../assets/dollar.png";
import cubes from "../assets/cubes.png";
import ref from "../assets/ref.png";
import withdraw from "../assets/withdraw.png";
import wallet from "../assets/wallet.png";
import clip from "../assets/clip.png";
import { useState, useRef, useContext, useEffect } from "react";
import { dashboardContext } from "../App";
import { useNavigate } from "react-router";
import { getLocalData, setLocalData } from "../services/auth";
import Loader from "../components/Loader";
import moment from "moment/moment";
import TradePrice from "../components/TradePrice";
import Chart from "../components/Chart";
import coin from "../assets/coin.png";

const Dashboard = () => {
  const navigate = useNavigate();
  const { userData, setUserData } = useContext(dashboardContext);
  // const [transactions, setTransactions] = useState(JSON.parse(getLocalData("transactions")));
  const getStoredTransactions = () => {
    try {
      return JSON.parse(getLocalData("transactions")) || [];
    } catch (error) {
      console.error("Error parsing transactions:", error);
      return [];
    }
  };

  const [transactions, setTransactions] = useState(getStoredTransactions());

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const [isPendingInvestment, setIsPendingInvestment] = useState(false);
  const [withdrawalRequested, setWithdrawalRequested] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState("");
  const [topUpAmount, setTopUpAmount] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [displayTopUpModal, setDisplayTopUpModal] = useState(false);
  const [showRejectModal, setRejectModal] = useState(false);
  const [showInvestModal, setShowInvestModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState("");
  const [isFetching, setIsfetching] = useState(false);
  const [isInvesting, setIsInvesting] = useState(false);

  const [btc, setBtc] = useState([]);
  const [eth, setEth] = useState([]);
  const [usdt, setUsdt] = useState([]);

  function handleCopy(e) {
    navigator.clipboard.writeText(e);
    console.log("====================================");
    console.log(e);
    console.log("====================================");
  }

  useEffect(() => {
    document.title = "Dwight Finance Solutions | Dashboard";
    setBtc(userData?.crypto?.btc);
    setEth(userData?.crypto?.eth);
    setUsdt(userData?.crypto?.usdt);
  }, []);

  // ============= Check Pending Investment ===========================
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));

    const crypto = JSON.parse(localStorage.getItem("cryptoData"));

    setBtc(crypto.btc);
    setEth(crypto.eth);
    setUsdt(crypto.usdt);

    const checkPendingInvestment = () => {
      if (!userData?.transaction || userData.transaction.length === 0) {
        setIsPendingInvestment(false);
        return;
      }

      const lastTransactionStatus =
        userData.transaction.reverse()[userData.transaction.length - 1]?.status;
      setIsPendingInvestment(lastTransactionStatus === "pending");
      setWithdrawalRequested(lastTransactionStatus === "requested");
    };

    checkPendingInvestment();
  }, [transactions]);

  const hideModal = () => {
    setShowModal(false);
    setDisplayTopUpModal(false);
    setRejectModal(false);
    setShowInvestModal(false);
    setShowMessageModal(false);
    setErr("");

    // navigate("/dashboard");
    window.location.href = "/dashboard";
  };

  const openModal = () => {
    setShowModal(true);
  };

  const openTopUpModal = () => {
    setDisplayTopUpModal(true);
  };

  // ---------- Check if logged in, if so then set userData ------------------
  useEffect(() => {
    const userData = JSON.parse(getLocalData("userData"));
    if (!userData || userData.msg) return navigate("/");
    if (
      userData.profile.role === "admin" ||
      userData.profile.role === "super"
    ) {
      navigate("/userlist");
    } else {
      navigate("/dashboard");
    }
  }, []);

  // ------------ Function to copy referal link -------------------
  function copy() {
    navigator.clipboard.writeText(userData.profile.referralLink);
    setShow(!show);
    setTimeout(() => {
      setShow(false);
    }, 1500);
  }

  // ------------ Fetch updated user ----------------------
  useEffect(() => {
    const localData = JSON.parse(getLocalData("userProfile"));

    setIsfetching(true);

    const handleFreshUserDetails = async () => {
      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/getuserdata", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email: localData.email,
          }),
        })
      ).json();

      setUserData(res);
      setLocalData("userData", res);
      setLocalData("cryptoData", res?.crypto);
      setLocalData("plans", res?.plans);
      setLocalData("transactions", res?.transaction);
      setTransactions(res?.transaction);
      setIsfetching(false);
    };
    handleFreshUserDetails();
  }, []);

  // -------------------------- Withdraw logic -----------------------
  const handleWithdraw = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      if (Number(withdrawalAmount) > userData.profile.availableWithdrawal) {
        throw new Error(
          "The withdrawal amount cannot exceed the available withdrawal balance."
        );
      } else if (Number(withdrawalAmount) <= 0) {
        throw new Error("The withdrawal amount cannot be Zero or less.");
      }

      const res = await (
        await fetch(
          "https://bitloomchain-api.bitloomchain.com/api/v1/handleUserwithdrawalrequest",
          {
            method: "POST",

            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({
              email: userData.profile.email,
              userId: userData.profile.id,
              withdrawalAmount,
            }),
          }
        )
      ).json();

      setLocalData("transactions", res.transaction);
      setTransactions(res.transaction);
      setUserData(res);
      setIsLoading(false);
      setShowModal(false);
      setErr("");
    } catch (error) {
      console.log(error);
      setErr(error.message);
      setIsLoading(false);
    }
  };

  const handleTopUp = (e) => {
    e.preventDefault();

    if (userData?.profile?.totalInvestment <= 0) {
      setErr(
        "The total investment must be greater than $0 to qualify for an investment top-up."
      );
    } else if (isPendingInvestment) {
      setRejectModal(true);
      setDisplayTopUpModal(false);
    } else {
      setShowInvestModal(true);
      setDisplayTopUpModal(false);
    }
  };

  // ----------------- Handle invest -----------------------------------
  const handleInvest = async () => {
    setIsInvesting(true);

    try {
      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/topup", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            _id: userData?.profile?.id,
            amount: topUpAmount,
          }),
        })
      ).json();

      setShowMessageModal(true);
      setShowInvestModal(false);

      setUserData(res);
      setLocalData("userData", res);
      setLocalData("transactions", res?.transaction);
      setIsInvesting(false);
    } catch (error) {
      console.log(error);
      setIsInvesting(false);
    }
  };

  // ==================== Withdrawal Modal ===========================
  const withdrawModal = () => {
    return (
      <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Withdraw</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => handleWithdraw(e)}>
            <Form.Group className="mb-3">
              <Form.Label>
                Please go to your{" "}
                <strong style={{ color: "#625f5f" }}>Profile</strong> and update
                your receiving crypto wallet network and wallet address, if you
                haven’t done so already.
              </Form.Label>
              <Form.Label>
                {" "}
                <strong> Enter withdrawal amount</strong>
              </Form.Label>

              <Form.Control
                required
                type="number"
                placeholder="Enter The amount you wish to withdraw"
                onChange={(e) => setWithdrawalAmount(e.target.value)}
              />
              <div style={{ height: "20px" }}>
                <span style={{ color: "red", fontSize: "13px" }}>{err}</span>
              </div>
            </Form.Group>

            <button
              className={`butt`}
              style={{
                width: "162px",
                height: "44px",
                marginTop: "40px",
                marginBottom: "10px",
              }}
            >
              {isLoading ? <Loader /> : "Request Withdrawal"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  // ==================== TopUp Modal ===========================
  const topUpModal = () => {
    return (
      <Modal show={displayTopUpModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Top-Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => handleTopUp(e)}>
            <Form.Group className="mb-3">
              <Form.Label>
                The Investment{" "}
                <strong style={{ color: "#625f5f" }}>Top-Up</strong> allows
                investors to increase their existing investment capital by
                adding additional funds to their current portfolio or investment
                plan. This enables greater returns while maintaining the
                original investment structure and benefits.
              </Form.Label>
              <Form.Label>
                {" "}
                <strong> Enter Top-Up amount</strong>
              </Form.Label>

              <Form.Control
                required
                type="number"
                placeholder="Enter the amount you wish to invest "
                onChange={(e) => setTopUpAmount(e.target.value)}
              />
              <div style={{ height: "20px" }}>
                <span style={{ color: "red", fontSize: "13px" }}>{err}</span>
              </div>
            </Form.Group>

            <button
              className={`butt`}
              style={{
                width: "162px",
                height: "44px",
                marginTop: "40px",
                marginBottom: "10px",
              }}
            >
              {isLoading ? <Loader /> : "Invest Now"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const rejectModal = () => {
    return (
      <Modal show={showRejectModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            <Alert.Heading>Pending payment</Alert.Heading>
            <p>
              Sorry, you have a pending payment, please proceed with the
              payment.
            </p>

            <p>
              If you have already made a payment and are still seeing this,
              please contact customer support.
            </p>

            {/* ============================== */}

            {userData?.crypto?.showBtc ? (
              <Row>
                <Col className="card-col">
                  <label className="apply-label">BTC (Bitcoin) Address:</label>
                  <br />

                  <div className="cryptoWrapper">
                    <input
                      type="text"
                      name="btc"
                      value={btc}
                      className="form-input clipInput"
                      id="full"
                      style={{ backgroundColor: "#be2e2e" }}
                      readOnly
                    />

                    <OverlayTrigger
                      trigger="click"
                      key="top"
                      placement="top"
                      overlay={<Tooltip id={`tooltip-$"top"`}>Copied!</Tooltip>}
                      delay={{ show: 250, hide: 400 }}
                    >
                      <img
                        src={clip}
                        alt="clip"
                        id="copyIcon"
                        onClick={() => handleCopy(btc)}
                      />
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {userData?.crypto?.showEth ? (
              <Row>
                <Col className="card-col">
                  <label className="apply-label">Ethereum (ETH) Address:</label>
                  <br />

                  <div className="cryptoWrapper">
                    <input
                      type="text"
                      name="btc"
                      value={eth}
                      className="form-input clipInput"
                      style={{ backgroundColor: "#be2e2e" }}
                      id="full"
                      readOnly
                    />

                    <OverlayTrigger
                      trigger="click"
                      key="top"
                      placement="top"
                      overlay={<Tooltip id={`tooltip-$"top"`}>Copied!</Tooltip>}
                      delay={{ show: 250, hide: 400 }}
                    >
                      <img
                        src={clip}
                        alt="clip"
                        id="copyIcon"
                        onClick={() => handleCopy(eth)}
                      />
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {userData?.crypto?.showUsdt ? (
              <Row>
                <Col className="card-col">
                  <label className="apply-label">USDT (TRC20) Address:</label>
                  <br />

                  <div className="cryptoWrapper">
                    <input
                      type="text"
                      name="btc"
                      value={usdt}
                      className="form-input clipInput"
                      style={{ backgroundColor: "#be2e2e" }}
                      id="full"
                      readOnly
                    />

                    <OverlayTrigger
                      trigger="click"
                      key="top"
                      placement="top"
                      overlay={<Tooltip id={`tooltip-$"top"`}>Copied!</Tooltip>}
                      delay={{ show: 250, hide: 400 }}
                    >
                      <img
                        src={clip}
                        alt="clip"
                        id="copyIcon"
                        onClick={() => handleCopy(usdt)}
                      />
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Alert>
        </Modal.Body>
      </Modal>
    );
  };

  const investModal = () => {
    return (
      <Modal show={showInvestModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <h4>Pay with Crypto</h4>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <img src={coin} alt="atm card" style={{ width: "75px" }} />
              </Col>
            </Row>

            <p>
              Please make your payment of{" "}
              <strong style={{ color: "#edb267" }}>
                ${Number(topUpAmount)?.toLocaleString()}
              </strong>{" "}
              to any of the Wallet Addresses below, and use the Payment
              Reference as remark. All payments are confirmed within 30-60 mins
              after the payment was made.
            </p>

            {userData?.crypto?.showBtc ? (
              <Row>
                <Col className="card-col">
                  <label className="apply-label">BTC (Bitcoin) Address:</label>
                  <br />

                  <div className="cryptoWrapper">
                    <input
                      type="text"
                      name="btc"
                      value={btc}
                      className="form-input clipInput"
                      id="full"
                      readOnly
                    />

                    <OverlayTrigger
                      trigger="click"
                      key="top"
                      placement="top"
                      overlay={<Tooltip id={`tooltip-$"top"`}>Copied!</Tooltip>}
                      delay={{ show: 250, hide: 400 }}
                    >
                      <img
                        src={clip}
                        alt="clip"
                        id="copyIcon"
                        onClick={() => handleCopy(btc)}
                      />
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {userData?.crypto?.showEth ? (
              <Row>
                <Col className="card-col">
                  <label className="apply-label">Ethereum (ETH) Address:</label>
                  <br />

                  <div className="cryptoWrapper">
                    <input
                      type="text"
                      name="btc"
                      value={eth}
                      className="form-input clipInput"
                      id="full"
                      readOnly
                    />

                    <OverlayTrigger
                      trigger="click"
                      key="top"
                      placement="top"
                      overlay={<Tooltip id={`tooltip-$"top"`}>Copied!</Tooltip>}
                      delay={{ show: 250, hide: 400 }}
                    >
                      <img
                        src={clip}
                        alt="clip"
                        id="copyIcon"
                        onClick={() => handleCopy(eth)}
                      />
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {userData?.crypto?.showUsdt ? (
              <Row>
                <Col className="card-col">
                  <label className="apply-label">USDT (TRC20) Address:</label>
                  <br />

                  <div className="cryptoWrapper">
                    <input
                      type="text"
                      name="btc"
                      value={usdt}
                      className="form-input clipInput"
                      id="full"
                      readOnly
                    />

                    <OverlayTrigger
                      trigger="click"
                      key="top"
                      placement="top"
                      overlay={<Tooltip id={`tooltip-$"top"`}>Copied!</Tooltip>}
                      delay={{ show: 250, hide: 400 }}
                    >
                      <img
                        src={clip}
                        alt="clip"
                        id="copyIcon"
                        onClick={() => handleCopy(usdt)}
                      />
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Row>
              <Col style={{ marginTop: "20px" }}>
                <button
                  type="button"
                  id="next-btn"
                  onClick={handleInvest}
                  disabled={isInvesting}
                >
                  {isInvesting ? <Loader /> : "Done"}
                </button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const paymentMessageModal = () => {
    return (
      <Modal show={showMessageModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success">
            <Alert.Heading>Payment pending</Alert.Heading>
            Your payment of{" "}
            <strong> ${Number(topUpAmount)?.toLocaleString()} </strong> is
            pending, kindly wait as we confirm it. This process typically takes
            30 to 60 minutes. You will be able to invest again if the payment
            has been confirmed.
          </Alert>
        </Modal.Body>
      </Modal>
    );
  };

  const validateWithdrawal =
    userData.profile &&
    userData.profile.availableWithdrawal > 0 &&
    withdrawalRequested !== true;

  // ====================================≠≠≠≠≠≠≠≠≠≠======================

  return (
    <div>
      <TradePrice />
      <LoggedInNavbar isFetching={isFetching} />

      {withdrawModal()}
      {topUpModal()}
      {rejectModal()}
      {investModal()}
      {paymentMessageModal()}

      <Container fluid className="dashboard">
        <span id="dashboardLabel">Referral Link</span>

        <div className="clipSection" ref={target} onClick={copy}>
          <input
            type="text"
            readOnly
            className="clipboardinput"
            value={userData.profile && userData.profile.referralLink}
          />
          <div className="clipIconHolder">
            <img src={clip} alt="clip" id="clipIcon" />
          </div>
        </div>

        <Overlay target={target.current} show={show} placement="top">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              Link copied!
            </Tooltip>
          )}
        </Overlay>

        <Row>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Wallet Balance</span>
                <h3>{`$${
                  userData.profile
                    ? (
                        Number(userData?.profile.totalInvestment) +
                        Number(userData?.profile.activeInvestment) +
                        Number(userData?.profile.referralEarning) +
                        Number(userData?.profile.totalWalletBalance)
                      ).toLocaleString()
                    : 0
                }`}</h3>
              </div>

              <img src={wallet} alt="icon" />
            </div>
          </Col>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Investments</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.totalInvestment.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              {/* <img src={cubes} alt="icon" /> */}
              <button
                className={`butt ${validateWithdrawal ? "" : "inactive"}`}
                // disabled={validateWithdrawal ? false : true}
                onClick={openTopUpModal}
              >
                Top-Up
              </button>
            </div>
          </Col>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Investments Profit</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.activeInvestment.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              <img src={dollar} alt="icon" />
            </div>
          </Col>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="case">
              <div className="dashboardCard">
                <div className="dashboardCardContent">
                  <span>Available Withdrawal</span>
                  <h3>{`$${
                    userData.profile
                      ? userData.profile.availableWithdrawal.toLocaleString()
                      : 0
                  }`}</h3>
                </div>

                <button
                  className={`butt ${validateWithdrawal ? "" : "inactive"}`}
                  disabled={validateWithdrawal ? false : true}
                  onClick={openModal}
                >
                  Withdraw
                </button>
              </div>

              <div className="cover">
                <div className="partOne">
                  <span
                    style={{
                      color: "rgb(186 105 7)",
                      fontSize: 12,
                      width: "50%",
                    }}
                  >
                    Please note that the withdraw button is only active when
                    there are withdrawable funds available.
                  </span>
                </div>
                {/* <div className="partOne"></div> */}
              </div>
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Withdrawal</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.totalWithdrawal.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              <img src={withdraw} alt="icon" />
            </div>
          </Col>
          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Referral Earning</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.referralEarning.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              <img src={ref} alt="icon" />
            </div>
          </Col>
        </Row>
        <br />

        <Chart />

        <span id="dashboardLabel">Transactions</span>

        <div>
          <Table size="sm" responsive>
            <tr>
              <th>S/N</th>
              <th>Date</th>
              <th>Reference ID</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Profit</th>
              <th>Details</th>
              <th>Status</th>
            </tr>
            <>
              {transactions &&
                transactions.map(
                  (
                    {
                      id,
                      amount,
                      createdAt,
                      details,
                      profit,
                      referenceId,
                      status,
                      type,
                    },
                    index
                  ) => {
                    return (
                      <tr key={id}>
                        <td>{index + 1}</td>
                        <td>
                          {moment.utc(createdAt.split("T")[0]).format("ll")}
                        </td>
                        <td id="referrenceID">{referenceId}</td>
                        <td>{type}</td>

                        <td
                          style={{
                            color: `${
                              type === "Withdrawal Request" ? "red" : ""
                            }`,
                          }}
                        >
                          {` ${
                            type === "Withdrawal Request" ? "-" : ""
                          } $${amount.toLocaleString()}`}
                        </td>

                        <td id="plusAmount">
                          {profit === 0 ? "" : `+$${profit.toLocaleString()}`}
                        </td>
                        <td>{details}</td>
                        <td>
                          <span
                            style={{
                              color:
                                status === "pending"
                                  ? "rgb(120 99 9)"
                                  : status === "invalid"
                                  ? "red"
                                  : status === "active"
                                  ? "#F76300"
                                  : "",
                              fontWeight: "600",
                            }}
                          >
                            {status}
                          </span>
                        </td>
                      </tr>
                    );
                  }
                )}
            </>
          </Table>
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
