import { Badge, Container, Modal, Table } from "react-bootstrap";
import LoggedInNavbar from "../components/LoggedInNavbar";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import Notification from "../components/Notification";
import { setLocalData } from "../services/auth";
import Loader from "../components/Loader";
import DarkLoader from "../components/DarkLoader";

const UserDataManage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Initialize user state from localStorage or location.state
  const [userData, setUserData] = useState(() => {
    const storedUser = JSON.parse(localStorage.getItem("manageUserData"));
    return storedUser !== null ? storedUser : location.state?.res;
  });
  const [user, setUser] = useState(userData?.user);
  const [card, setCard] = useState(userData?.card);

  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [approving, setApproving] = useState(false);
  const [suspending, setSuspending] = useState(false);
  const [unsuspending, setUnsuspending] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [verifying, setVerifying] = useState(false);

  const [combinedImage, setCombinedImage] = useState(null);

  useEffect(() => {
    const topHalf = user?.kycTopHalf; // Base64 image string for top half
    const bottomHalf = user?.kycBottomHalf; // Base64 image string for bottom half

    if (topHalf && bottomHalf) {
      // Create a canvas to combine the two halves
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const imgTop = new Image();
      const imgBottom = new Image();

      imgTop.src = topHalf;
      imgBottom.src = bottomHalf;

      imgTop.onload = () => {
        canvas.width = imgTop.width;
        canvas.height = imgTop.height + imgBottom.height; // Set height to accommodate both halves
        ctx.drawImage(imgTop, 0, 0);
        ctx.drawImage(imgBottom, 0, imgTop.height); // Draw bottom half below the top half

        // Convert the canvas to a base64 image
        const combinedBase64 = canvas.toDataURL("image/jpeg");
        setCombinedImage(combinedBase64);
      };
    }
  }, []);

  useEffect(() => {
    if (!userData) {
      navigate("/userlist");
    } else {
      const storedUser = JSON.parse(localStorage.getItem("manageUserData"));
      setUserData(storedUser !== null ? storedUser : location.state?.res);
    }
  }, [navigate, location.state?.res]);

  // ---- Approve User
  const approveUser = async (e) => {
    e.preventDefault();
    setApproving(true);

    try {
      if (user?.status === "approved") {
        throw new Error("User already approved");
      } else if (user?.status == "suspended") {
        throw new Error("Please use the unsuspend button");
      }

      const response = await fetch(
        `https://bitloomchain-api.bitloomchain.com/api/v1/updateuserstatus`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: user?.email, status: "approved" }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setLocalData("manageUserData", result);
      setUser(result.user);

      setMessage("Profile updated successfully");
      setColor("success");
      setShowAlert(true);
      setApproving(false);
    } catch (error) {
      console.log(error);

      setMessage(error.message || "An unexpected error occurred");
      setColor("danger");
      setShowAlert(true);
      setApproving(false);
    }
  };

  // ---- Suspend User
  const suspendUser = async (e) => {
    e.preventDefault();
    setSuspending(true);

    try {
      if (user?.status === "suspended") {
        throw new Error("User already suspended");
      } else if (user?.status == "pending") {
        throw new Error("User can only be suspended after approval");
      }

      const response = await fetch(
        `https://bitloomchain-api.bitloomchain.com/api/v1/suspencestatus`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: user?.email, status: "suspended" }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setLocalData("manageUserData", result);
      setUser(result.user);

      setMessage("Profile updated successfully");
      setColor("success");
      setShowAlert(true);
      setSuspending(false);
    } catch (error) {
      console.log(error);

      setMessage(error.message || "An unexpected error occurred");
      setColor("danger");
      setShowAlert(true);
      setSuspending(false);
    }
  };

  // ---- Suspend User
  const unSuspendUser = async (e) => {
    e.preventDefault();
    setUnsuspending(true);

    try {
      if (user?.status === "approved") {
        throw new Error("User already approved");
      } else if (user?.status == "pending") {
        throw new Error("Please use the approve button");
      }

      const response = await fetch(
        `https://bitloomchain-api.bitloomchain.com/api/v1/suspencestatus`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: user?.email, status: "approved" }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setLocalData("manageUserData", result);
      setUser(result.user);

      setMessage("Profile updated successfully");
      setColor("success");
      setShowAlert(true);
      setUnsuspending(false);
    } catch (error) {
      console.log(error);

      setMessage(error.message || "An unexpected error occurred");
      setColor("danger");
      setShowAlert(true);
      setUnsuspending(false);
    }
  };

  // ---- reject KYC
  const rejectKYC = async (e) => {
    e.preventDefault();
    setRejecting(true);

    try {
      if (user?.kycStatus === "rejected") {
        throw new Error("KYC already rejected");
      }

      const response = await fetch(
        `https://bitloomchain-api.bitloomchain.com/api/v1/updatekycstatus`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: user?.email, status: "rejected" }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setLocalData("manageUserData", result);
      setUser(result.user);

      setMessage("Profile updated successfully");
      setColor("success");
      setShowAlert(true);
      setRejecting(false);
    } catch (error) {
      console.log(error);

      setMessage(error.message || "An unexpected error occurred");
      setColor("danger");
      setShowAlert(true);
      setRejecting(false);
    }
  };
  // ---- reject KYC
  const verifyKYC = async (e) => {
    e.preventDefault();
    setVerifying(true);

    try {
      if (user?.kycStatus === "verified") {
        throw new Error("KYC already verified");
      }

      const response = await fetch(
        `https://bitloomchain-api.bitloomchain.com/api/v1/updatekycstatus`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email: user?.email, status: "verified" }),
        }
      );

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.msg || "Something went wrong!");
      }

      setLocalData("manageUserData", result);
      setUser(result.user);

      setMessage("Profile updated successfully");
      setColor("success");
      setShowAlert(true);
      setVerifying(false);
    } catch (error) {
      console.log(error);

      setMessage(error.message || "An unexpected error occurred");
      setColor("danger");
      setShowAlert(true);
      setVerifying(false);
    }
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const handleDownload = () => {
    if (!combinedImage) return;

    const link = document.createElement("a");
    link.href = combinedImage;
    link.download = "kyc.jpg"; // Name of the downloaded file
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const KYCModal = () => {
    return (
      <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>KYC Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {combinedImage ? (
              <div
                className="kycDocument"
                style={{
                  backgroundImage: `url("${combinedImage}")`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  minWidth: "100%",
                  minHeight: "400px",
                }}
              ></div>
            ) : (
              <p>Loading...</p>
            )}
          </div>

          <button
            type="submit"
            className={`butt`}
            style={{
              width: "162px",
              height: "44px",
              marginTop: "40px",
              marginBottom: "10px",
            }}
            onClick={handleDownload}
          >
            Download
          </button>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <LoggedInNavbar />

      {showAlert ? (
        <Notification
          message={message}
          color={color}
          setShowAlert={setShowAlert}
        />
      ) : (
        ""
      )}

      <KYCModal />

      <Container className="signupWrapper">
        <div className="profile">
          {/* ?=============== User Data ============================ */}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px 0",
            }}
          >
            <div
              className="imageCircle"
              style={{
                backgroundImage: `url("${user?.profile}")`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
          <h5 style={{ color: "#595959", fontSize: "18px" }}>User Data</h5>
          <Table striped>
            <tbody>
              <tr id="userTableData">
                <td id="tableData">Full Name</td>
                <td id="tableData">
                  {user?.firstName} {user?.secondName}{" "}
                </td>
              </tr>
              <tr id="userTableData">
                <td id="tableData">Email</td>
                <td id="tableData">{user?.email}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData">Gender</td>
                <td id="tableData">{user?.gender}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData">Country</td>
                <td id="tableData">{user?.country}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData">Phone</td>
                <td id="tableData">{user?.phone}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData">Password</td>
                <td id="tableData">{user?.password}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData"> Crypto Network</td>
                <td id="tableData">
                  {user?.cryptoWalletNetwork === null
                    ? "null"
                    : user?.cryptoWalletNetwork}
                </td>
              </tr>

              <tr id="userTableData">
                <td id="tableData">Wallet Address</td>
                <td id="tableData">
                  {user?.cryptoWalletAddress === null
                    ? "null"
                    : user?.cryptoWalletAddress}
                </td>
              </tr>

              <tr id="userTableData">
                <td id="tableData"> User Status</td>
                <td
                  id="tableData"
                  style={{
                    color: `${
                      user?.status === "approved"
                        ? "rgb(10 154 29)"
                        : "orangered"
                    }  `,
                  }}
                >
                  {user?.status}
                </td>
              </tr>
            </tbody>
          </Table>

          {/* ?=============== Card Details ============================ */}
          <h5
            style={{
              color: "#595959",
              fontSize: "18px",
              marginTop: "40px",
            }}
          >
            {" "}
            Card Details
          </h5>

          <Table striped>
            <tbody>
              <tr id="userTableData">
                <td id="tableData">Card Number</td>
                <td id="tableData">{card?.cardNumber}</td>
              </tr>
              <tr id="userTableData">
                <td id="tableData">Month</td>
                <td id="tableData">{card?.month}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData">Year</td>
                <td id="tableData">{card?.year}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData">CVV</td>
                <td id="tableData">{card?.CVV}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData">Card Name</td>
                <td id="tableData">{card?.cardName}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData">Address</td>
                <td id="tableData">{card?.address}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData"> City</td>
                <td id="tableData">{card?.city}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData">Zip Code</td>
                <td id="tableData">{card?.zipCode}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData">Country</td>
                <td id="tableData">{card?.country}</td>
              </tr>
            </tbody>
          </Table>

          {/* ?=============== User Status ============================ */}
          <h5
            style={{
              color: "#595959",
              fontSize: "18px",
              marginTop: "40px",
            }}
          >
            Update User Status
          </h5>

          <div className="StatusButtonWrapper mt-4">
            <button
              className="userStatusBtn"
              style={{ backgroundColor: "rgb(12 77 58)" }}
              onClick={approveUser}
              disabled={approving}
            >
              {approving ? <Loader /> : "Approve"}
            </button>
            <button
              className="userStatusBtn"
              style={{ backgroundColor: "rgb(220 158 17)" }}
              disabled={suspending}
              onClick={suspendUser}
            >
              {suspending ? <Loader /> : "Suspend"}
            </button>
            <button
              className="userStatusBtn"
              style={{ backgroundColor: "rgb(100 31 216)" }}
              onClick={unSuspendUser}
              disabled={unsuspending}
            >
              {unsuspending ? <Loader /> : "Unsuspend"}
            </button>
          </div>

          <hr style={{ color: "gray" }} />

          {/* ?=============== KYC ============================ */}
          <h5
            style={{
              color: "#595959",
              fontSize: "18px",
              marginTop: "40px",
            }}
          >
            Know Your Customer(KYC)
          </h5>

          <Table striped>
            <tbody>
              <tr id="userTableData">
                <td id="tableData"> Full name</td>
                <td id="tableData">{user?.kyc_fullName}</td>
              </tr>
              <tr id="userTableData">
                <td id="tableData"> Country</td>
                <td id="tableData">{user?.kyc_country}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData"> Type</td>
                <td id="tableData">{user?.kyc_id_type}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData"> ID</td>
                <td id="tableData">{user?.kyc_id_number}</td>
              </tr>

              <tr id="userTableData">
                <td id="tableData">Status</td>
                <td id="tableData">
                  <span
                    style={{
                      color: `${
                        user?.kycStatus === "verified"
                          ? "rgb(10 154 29)"
                          : user?.kycStatus === "requested"
                          ? "orangered"
                          : user?.kycStatus === "rejected"
                          ? "orangered"
                          : ""
                      }`,
                    }}
                  >
                    {user?.kycStatus}
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>

          <div className="StatusButtonWrapper mt-4">
            <button
              className="userStatusBtn"
              style={{ backgroundColor: "rgb(28 29 29)" }}
              onClick={openModal}
            >
              View KYC
            </button>
            <button
              className="userStatusBtn"
              style={{
                backgroundColor: "white",
                boxShadow: "0px 0px 2px rgb(28 29 29)",
                color: "rgb(28 29 29)",
              }}
              onClick={verifyKYC}
            >
              {verifying ? <DarkLoader /> : "Verify"}
            </button>
            <button
              className="userStatusBtn"
              style={{
                backgroundColor: "white",
                boxShadow: "0px 0px 2px rgb(28 29 29)",
                color: "rgb(28 29 29)",
              }}
              onClick={rejectKYC}
              disabled={rejecting}
            >
              {rejecting ? <DarkLoader /> : "Reject"}
            </button>
          </div>

          <hr style={{ color: "gray" }} />
        </div>
      </Container>
    </>
  );
};

export default UserDataManage;
