import React, { useEffect } from "react";
import { useState } from "react";
import {
  Alert,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import clip from "../assets/clip.png";
import Loader from "./Loader";
import Notification from "./Notification";
import LoggedInNavbar from "./LoggedInNavbar";

const InvestmentPlans = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [toShow, setToShow] = useState(false);
  const [isPendingInvestment, setIsPendingInvestment] = useState(false);
  const [userDetails, setUserExist] = useState([]);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(
    JSON.parse(localStorage.getItem("plans"))[0]
  );
  const [investmentAMount, setInvestmentAmiunt] = useState(null);

  const [btc, setBtc] = useState([]);
  const [eth, setEth] = useState([]);
  const [usdt, setUsdt] = useState([]);

  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [cryptos, setCryptos] = useState([]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    setUserExist(userData);

    const crypto = JSON.parse(localStorage.getItem("cryptoData"));
    const plans = JSON.parse(localStorage.getItem("plans"));

    setBtc(crypto.btc);
    setEth(crypto.eth);
    setUsdt(crypto.usdt);
    setCryptos(crypto);
    setPlans(plans);
    setSelectedPlan(plans[0]);

    const checkPendingInvestment = () => {
      if (!userData?.transaction || userData.transaction.length === 0) {
        setIsPendingInvestment(false);
        return;
      }

      const lastTransactionStatus =
        userData.transaction.reverse()[userData.transaction.length - 1]?.status;
      setIsPendingInvestment(lastTransactionStatus === "pending");
    };

    checkPendingInvestment();
  }, []);

  function handleCopy(e) {
    navigator.clipboard.writeText(e);
  }

  const handleClose = () => {
    setToShow(false);
    setIsLoading(false);

    navigate("/dashboard");
  };

  // -------------- Update current transaction on  pageload ---------------------
  useEffect(() => {}, []);

  // --------- Handle Payment --------
  const handlePaymentPage = (e) => {
    e.preventDefault();

    const { minimum, maximum, name } = selectedPlan;

    if (investmentAMount < minimum) {
      setMessage(
        `Investment amount cannot be less than $${minimum.toLocaleString()} `
      );
      setColor("danger");
      setShowAlert(true);
    } else if (investmentAMount > maximum) {
      setMessage(
        `Investment amount cannot be more than $${maximum.toLocaleString()} `
      );
      setColor("danger");
      setShowAlert(true);
    } else {
      if (isPendingInvestment) {
        setToShow(true);
      } else {
        console.log("Not pending");
        navigate("/payment", {
          state: { investmentAMount, name, userDetails },
        });
      }
    }
  };

  const rejectModal = () => {
    return (
      <Modal show={toShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            <Alert.Heading>Pending payment</Alert.Heading>
            <p>
              Sorry, you have a pending payment, please proceed with the
              payment.
            </p>

            <p>
              If you have already made a payment and are still seeing this,
              please contact customer support.
            </p>

            {/* ============================== */}

            {cryptos?.showBtc ? (
              <Row>
                <Col className="card-col">
                  <label className="apply-label">BTC (Bitcoin) Address:</label>
                  <br />

                  <div className="cryptoWrapper">
                    <input
                      type="text"
                      name="btc"
                      value={btc}
                      className="form-input clipInput"
                      id="full"
                      style={{ backgroundColor: "#be2e2e" }}
                      readOnly
                    />

                    <OverlayTrigger
                      trigger="click"
                      key="top"
                      placement="top"
                      overlay={<Tooltip id={`tooltip-$"top"`}>Copied!</Tooltip>}
                      delay={{ show: 250, hide: 400 }}
                    >
                      <img
                        src={clip}
                        alt="clip"
                        id="copyIcon"
                        onClick={() => handleCopy({ btc })}
                      />
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {cryptos?.showEth ? (
              <Row>
                <Col className="card-col">
                  <label className="apply-label">Ethereum (ETH) Address:</label>
                  <br />

                  <div className="cryptoWrapper">
                    <input
                      type="text"
                      name="btc"
                      value={eth}
                      className="form-input clipInput"
                      style={{ backgroundColor: "#be2e2e" }}
                      id="full"
                      readOnly
                    />

                    <OverlayTrigger
                      trigger="click"
                      key="top"
                      placement="top"
                      overlay={<Tooltip id={`tooltip-$"top"`}>Copied!</Tooltip>}
                      delay={{ show: 250, hide: 400 }}
                    >
                      <img
                        src={clip}
                        alt="clip"
                        id="copyIcon"
                        onClick={() => handleCopy({ eth })}
                      />
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}

            {cryptos?.showUsdt ? (
              <Row>
                <Col className="card-col">
                  <label className="apply-label">USDT (TRC20) Address:</label>
                  <br />

                  <div className="cryptoWrapper">
                    <input
                      type="text"
                      name="btc"
                      value={usdt}
                      className="form-input clipInput"
                      style={{ backgroundColor: "#be2e2e" }}
                      id="full"
                      readOnly
                    />

                    <OverlayTrigger
                      trigger="click"
                      key="top"
                      placement="top"
                      overlay={<Tooltip id={`tooltip-$"top"`}>Copied!</Tooltip>}
                      delay={{ show: 250, hide: 400 }}
                    >
                      <img
                        src={clip}
                        alt="clip"
                        id="copyIcon"
                        onClick={() => handleCopy({ usdt })}
                      />
                    </OverlayTrigger>
                  </div>
                </Col>
              </Row>
            ) : (
              ""
            )}
          </Alert>
        </Modal.Body>
      </Modal>
    );
  };

  // Handle the selection of a plan
  const handleSelectPlan = (e) => {
    const selectedType = e.target.value;
    const plan = plans.find(({ name }) => name === selectedType);
    setSelectedPlan(plan);
  };

  // console.log(selectedPlan);
  
  return (
    <>
      <LoggedInNavbar />

      <Container className="formContainer">
        {/* ========== Modal Section =================  */}
        {rejectModal()}

        {showAlert ? (
          <Notification
            message={message}
            color={color}
            setShowAlert={setShowAlert}
          />
        ) : (
          ""
        )}

        <div className="formHolder">
          <h3 id="formTitle">Investment Plan</h3>
          <Form onSubmit={handlePaymentPage}>
            <Form.Group className="mb-3">
              <Form.Label id="formLabel">Choose Plan</Form.Label>
              <Form.Select required onChange={handleSelectPlan}>
                <React.Fragment>
                  {plans && plans.length > 0 ? (
                    plans.map(({ id, name }) => (
                      <option value={name} key={id}>
                        {name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No plans available
                    </option>
                  )}
                </React.Fragment>
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label id="formLabel">
                Investment amount ${selectedPlan?.minimum.toLocaleString()} - $
                {selectedPlan?.maximum.toLocaleString()}
              </Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Enter investment amount"
                className="shadow-none"
                value={investmentAMount}
                onInput={(e) => setInvestmentAmiunt(e.target.value)}
              />
            </Form.Group>

            <p>{selectedPlan?.percent}</p>

            <button type="submit" id="logBtn" disabled={isLoading}>
              {isLoading ? <Loader /> : "Proceed"}
            </button>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default InvestmentPlans;
