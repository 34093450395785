import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Container,
  Dropdown,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import LoggedInNavbar from "../components/LoggedInNavbar";
import { getLocalData, setLocalData } from "../services/auth";
import FetchLoader from "../components/FetchLoader";
import Loader from "../components/Loader";
import moment from "moment";
import DynamicFooter from "../components/DynamicFooter";

const UserList = () => {
  const navigate = useNavigate();
  const [allusers, setAllUsers] = useState([]);

  const [userRole, setUserRole] = useState([]);

  const [isloading, setIsloading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    document.title = "Dwight Finance Solutions | Users";
  }, []);

  // -------------- Check Current User Role to see if eligible to view the users page ------------
  useEffect(() => {
    const currentUser = JSON.parse(getLocalData("userData"));
    if (
      currentUser.profile.role === "super" ||
      currentUser.profile.role === "admin"
    ) {
      return setUserRole(currentUser.profile.role);
    }
    navigate("/dashboard");
  }, []);

  // -------- Get all users from the server ----------------
  useEffect(() => {
    setIsloading(true);
    const getAllUsers = async () => {
      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/allusers")
      ).json();

      setAllUsers(res);
      setIsloading(false);
    };

    getAllUsers();
  }, []);

  const handleManage = async (data) => {
    try {
      setIsFetching(true);

      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/entiredata", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email: data,
          }),
        })
      ).json();

      setLocalData("manageUserData", res);

      navigate("/usermanage", { state: { res } });
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleManipulate = async (data) => {
    navigate("/usertransaction", { state: { data } });
  };

  return (
    <>
      <LoggedInNavbar />

      {isloading ? <FetchLoader /> : ""}

      <Container className="referralPage" fluid>
        <h3 id="aboutText1">All Users</h3>
        <Table size="sm" responsive>
          <tr>
            <th>S/N</th>
            <th>Fullname</th>
            <th>Email</th>
            <th>Joined At</th>
            <th>Transaction</th>
            <th>User Details</th>
          </tr>

          <>
            {allusers &&
              allusers.map(
                (
                  { id, firstName, secondName, email, role, createdAt },
                  index
                ) => (
                  <tr key={id}>
                    <td>{index + 1}</td>
                    <td>{`${firstName} ${secondName}`}</td>
                    <td>{email}</td>

                    <td>{moment.utc(createdAt.split("T")[0]).format("ll")}</td>

                    <td>
                      <Button
                        size="sm"
                        style={{
                          backgroundColor: "#278ca7",
                          border: "none",
                          width: 90,
                          height: 40,
                        }}
                        onClick={() => handleManipulate(email)}
                        disabled={
                          userRole && userRole === "admin" ? true : false
                        }
                      >
                        Manipulate
                      </Button>
                    </td>
                    <td>
                      <Button
                        size="sm"
                        style={{
                          backgroundColor: "#198754",
                          border: "none",
                          color: "white",
                          width: 90,
                          height: 40,
                        }}
                        onClick={() => handleManage(email)}
                        disabled={
                          userRole && userRole === "admin" ? true : false
                        }
                      >
                        {isFetching ? <Loader /> : "manage"}
                      </Button>
                    </td>
                  </tr>
                )
              )}
          </>
        </Table>
      </Container>
    </>
  );
};

export default UserList;
