import { useEffect, useState } from "react";
import LoggedInNavbar from "../components/LoggedInNavbar";
import {
  Alert,
  Col,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import cards from "../assets/cards.png";
import coin from "../assets/coin.png";
import clip from "../assets/clip.png";
import CountryList from "../components/CountryList";
import { useLocation } from "react-router";
import Loader from "../components/Loader";
import { useNavigate } from "react-router";

const PaymentPage = () => {
  const [isFetching, setIsfetching] = useState(false);
  const [show, setShow] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const amount = location.state?.investmentAMount;
  const type = location.state?.name;
  const userData = location.state?.userDetails;
  const [isloading, setIsLoading] = useState(false);
  const [sendingCard, setSendingCard] = useState(false);
  const [isPendingInvestment, setIsPendingInvestment] = useState(false);
  const [display, setDisplay] = useState(false);
  const [pendingAmount, setPendingAmount] = useState(null);
  const [updatedTransaction, setUpdatedTransaction] = useState(
    userData ? userData?.transaction : []
  );
  const [btc, setBtc] = useState([]);
  const [eth, setEth] = useState([]);
  const [usdt, setUsdt] = useState([]);

  const [cryptos, setCryptos] = useState([]);

  useEffect(() => {
    const amount = localStorage.getItem("pendingAmount");
    const crypto = JSON.parse(localStorage.getItem("cryptoData"));

    setCryptos(crypto);
    setBtc(crypto.btc);
    setEth(crypto.eth);
    setUsdt(crypto.usdt);

    setPendingAmount(JSON.parse(amount));
  }, []);

  const handleCancle = () => {
    setDisplay(false);
    navigate("/dashboard");
    // window.location.href = "/dashboard";
  };
  const handleDisplay = () => setDisplay(true);

  const [formData, setFormData] = useState({
    cardNumber: "",
    cardName: "",
    CVV: "",
    expiryDate: {
      month: "",
      year: "",
    },
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    zipCode: "",
    country: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "expiryDate.month" || name === "expiryDate.year") {
      setFormData({
        ...formData,
        expiryDate: {
          ...formData.expiryDate,
          [name.split(".")[1]]: value,
        },
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  function handleCopy(e) {
    navigator.clipboard.writeText(e);
  }

  //   const handleShow = () =>
  const handleClose = () => setShow(false);

  // -------------- Update current transaction on  pageload ---------------------
  useEffect(() => {
    const allTransaction = async () => {
      const allTransactionStatus = [];

      updatedTransaction?.map(({ status }) => {
        allTransactionStatus.push(status);

        setIsPendingInvestment(allTransactionStatus.includes("pending"));
      });

      const pending =
        updatedTransaction[updatedTransaction.length - 1].status === "pending"
          ? true
          : false;
      setIsPendingInvestment(pending);

      const waitingAmount = JSON.parse(localStorage.getItem("pendingAmount"));

      setPendingAmount(pending ? waitingAmount : amount);
    };

    allTransaction();
  }, [updatedTransaction]);

  // ----------------- Handle invest -----------------------------------
  const handleInvest = async () => {
    if (isPendingInvestment === true) {
      setDisplay(true);
      return;
    }
    setIsLoading(true);

    try {
      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/invest", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            _id: userData.profile.id,
            amount,
          }),
        })
      ).json();

      if (res) {
        setDisplay(true);
        setUpdatedTransaction(res);
        localStorage.setItem("pendingAmount", JSON.stringify(amount));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // ----------------- Handle Post Card -----------------------------------
  const takeCard = async (e) => {
    e.preventDefault();
    setSendingCard(true);
    const {
      cardName,
      cardNumber,
      expiryDate,
      CVV,
      firstName,
      lastName,
      city,
      address,
      zipCode,
      country,
    } = formData;

    const email = userData.profile.email;

    const res = await (
      await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/addcard", {
        method: "POST",

        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          cardName,
          cardNumber,
          expiryDate,
          CVV,
          firstName,
          lastName,
          city,
          address,
          zipCode,
          country,
          email,
        }),
      })
    ).json();

    if (res) {
      setShow(true);
      setSendingCard(false);
    }

    console.log(res);
  };

  return (
    <>
      <LoggedInNavbar isFetching={isFetching} />

      {/* ----- Card Modal -------- */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            <Alert.Heading>Payment Method Unavailable</Alert.Heading>
            At the moment, card payment is unavailable. Kindly proceed with the
            payment using the crypto currency method.
          </Alert>
        </Modal.Body>
      </Modal>

      {/* ----- Crypto Modal -------- */}
      <Modal show={display} onHide={handleCancle}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="success">
            <Alert.Heading>Payment pending</Alert.Heading>
            Your payment of{" "}
            <strong> ${Number(pendingAmount)?.toLocaleString()} </strong> is
            pending, kindly wait as we confirm it. This process typically takes
            30 to 60 minutes. You will be able to invest again if the payment
            has been confirmed.
          </Alert>
        </Modal.Body>
      </Modal>

      <Container>
        <Row>
          <Col lg="6">
            <Form id="apply-Form" onSubmit={takeCard}>
              <Row>
                <Col>
                  <h4>Pay with Card</h4>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <img src={cards} alt="atm card" style={{ width: "75px" }} />
                </Col>
              </Row>

              <Row>
                <Col className="card-col">
                  <label className="apply-label">Card number:</label>
                  <br />
                  <input
                    required
                    type="number"
                    name="cardNumber"
                    value={formData.cardNumber}
                    onChange={handleChange}
                    className="form-input"
                    id="full"
                    placeholder="Card name"
                  />
                </Col>
              </Row>

              <Row>
                <Col className="card-col">
                  <label className="apply-label">Month:</label>
                  <br />
                  <input
                    type="number"
                    name="expiryDate.month"
                    value={formData.expiryDate.month}
                    onChange={handleChange}
                    className="form-input"
                    id="full"
                    placeholder="Month"
                    required
                  />
                </Col>

                <Col className="card-col">
                  <label className="apply-label">Year:</label>
                  <br />
                  <input
                    type="number"
                    name="expiryDate.year"
                    value={formData.expiryDate.year}
                    onChange={handleChange}
                    className="form-input"
                    id="full"
                    placeholder="Year"
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col className="card-col">
                  <label className="apply-label">CVV:</label>
                  <br />
                  <input
                    type="number"
                    name="CVV"
                    value={formData.CVV}
                    onChange={handleChange}
                    className="form-input"
                    id="full"
                    placeholder="CVV"
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col className="card-col">
                  <label className="apply-label">Card name:</label>
                  <br />
                  <input
                    type="text"
                    name="cardName"
                    value={formData.cardName}
                    onChange={handleChange}
                    className="form-input"
                    id="full"
                    placeholder="Card name"
                    required
                  />
                </Col>
              </Row>

              <h4 style={{ marginTop: "20px" }}>Billing details</h4>

              <Row>
                <Col className="card-col">
                  <label className="apply-label">First name:</label>
                  <br />
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="form-input"
                    id="full"
                    placeholder="First name"
                    required
                  />
                </Col>

                <Col className="card-col">
                  <label className="apply-label">Last name:</label>
                  <br />
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="form-input"
                    id="full"
                    placeholder="Last name"
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col className="card-col">
                  <label className="apply-label">Address line:</label>
                  <br />
                  <input
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className="form-input"
                    id="full"
                    placeholder="Address"
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col className="card-col">
                  <label className="apply-label">City:</label>
                  <br />
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    className="form-input"
                    id="full"
                    placeholder="City"
                    required
                  />
                </Col>

                <Col className="card-col">
                  <label className="apply-label">Zip code:</label>
                  <br />
                  <input
                    type="number"
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleChange}
                    className="form-input"
                    id="full"
                    placeholder="zip code"
                    required
                  />
                </Col>
              </Row>

              <Row>
                <Col className="card-col">
                  <label className="apply-label">Country:</label>
                  <br />
                  <CountryList
                    data={{ formData, setFormData, name: "country" }}
                  />
                </Col>
              </Row>

              <Row style={{ marginTop: "20px" }}>
                <Col> </Col>
                <Col style={{ textAlign: "right" }}>
                  <button type="submit" id="next-btn">
                    {sendingCard ? <Loader /> : "CONTINUE"}
                  </button>
                </Col>
              </Row>
            </Form>
          </Col>

          <Col lg="1">
            <h5 id="or">Or</h5>
          </Col>

          {/* =============================================================== */}
          <Col lg="5">
            <Form id="apply-Form">
              <Row>
                <Col>
                  <h4>Pay with Crypto</h4>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <img src={coin} alt="atm card" style={{ width: "75px" }} />
                </Col>
              </Row>

              <p>
                Please make your payment of{" "}
                <strong style={{ color: "#edb267" }}>
                  ${Number(amount)?.toLocaleString()}
                </strong>{" "}
                {type} investment to any of the Wallet Addresses below, and use the
                Payment Reference as remark. All payments are confirmed within
                30-60 mins after the payment was made.
              </p>

              {cryptos?.showBtc ? (
                <Row>
                  <Col className="card-col">
                    <label className="apply-label">
                      BTC (Bitcoin) Address:
                    </label>
                    <br />

                    <div className="cryptoWrapper">
                      <input
                        type="text"
                        name="btc"
                        value={btc}
                        className="form-input clipInput"
                        id="full"
                        readOnly
                      />

                      <OverlayTrigger
                        trigger="click"
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-$"top"`}>Copied!</Tooltip>
                        }
                        delay={{ show: 250, hide: 400 }}
                      >
                        <img
                          src={clip}
                          alt="clip"
                          id="copyIcon"
                          onClick={() => handleCopy(btc)}
                        />
                      </OverlayTrigger>
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              {cryptos?.showEth ? (
                <Row>
                  <Col className="card-col">
                    <label className="apply-label">
                      Ethereum (ETH) Address:
                    </label>
                    <br />

                    <div className="cryptoWrapper">
                      <input
                        type="text"
                        name="btc"
                        value={eth}
                        className="form-input clipInput"
                        id="full"
                        readOnly
                      />

                      <OverlayTrigger
                        trigger="click"
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-$"top"`}>Copied!</Tooltip>
                        }
                        delay={{ show: 250, hide: 400 }}
                      >
                        <img
                          src={clip}
                          alt="clip"
                          id="copyIcon"
                          onClick={() => handleCopy(eth)}
                        />
                      </OverlayTrigger>
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              {cryptos?.showUsdt ? (
                <Row>
                  <Col className="card-col">
                    <label className="apply-label">USDT (TRC20) Address:</label>
                    <br />

                    <div className="cryptoWrapper">
                      <input
                        type="text"
                        name="btc"
                        value={usdt}
                        className="form-input clipInput"
                        id="full"
                        readOnly
                      />

                      <OverlayTrigger
                        trigger="click"
                        key="top"
                        placement="top"
                        overlay={
                          <Tooltip id={`tooltip-$"top"`}>Copied!</Tooltip>
                        }
                        delay={{ show: 250, hide: 400 }}
                      >
                        <img
                          src={clip}
                          alt="clip"
                          id="copyIcon"
                          onClick={() => handleCopy(usdt)}
                        />
                      </OverlayTrigger>
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              <Row>
                <Col style={{ marginTop: "20px" }}>
                  <button
                    type="button"
                    id="next-btn"
                    onClick={handleInvest}
                    disabled={isloading}
                  >
                    {isloading ? <Loader /> : "Done"}
                  </button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default PaymentPage;
