import { Col, Container, Form, Row } from "react-bootstrap";
import Footer from "../components/Footer";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import FormsNavbar from "../components/FormsNavbar";
import { useContext, useEffect, useState } from "react";
import Notification from "../components/Notification";
import Loader from "../components/Loader";
import { setLocalData } from "../services/auth";
import { dashboardContext } from "../App";
import { getLocalData } from "../services/auth";
import DynamicFooter from "../components/DynamicFooter";

const SignUp = () => {
  const { userData, setUserData } = useContext(dashboardContext);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [captcha, setCaptcha] = useState(0);
  const [country, setCountry] = useState("");
  const [code, setCode] = useState(0);

  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [gender, setGender] = useState("");

  useEffect(() => {
    document.title = "Dwight Finance Solutions | Signup";
  }, []);

  useEffect(() => {
    const userData = JSON.parse(getLocalData("userData"));
    if (userData) return navigate("/dashboard");
  }, []);

  const handleCountrySelection = (e) => {
    setCountry(e.target.value);
  };

  // ------------ Generate Captcha --------------------------
  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 9);
  };

  useEffect(() => {
    const nums = [];
    for (let i = 0; i < 6; i++) {
      nums.push(generateRandomNumber());
    }
    setCode(nums);
  }, []);

  // ------------- Handle form submission -----------------------
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true)
      if (password !== confirmPassword) throw "Password does not match!";
      else if (captcha !== code.join("")) throw "Captcha is incorrect";

      const otpCode = await (
        await fetch(`https://bitloomchain-api.bitloomchain.com/api/v1/otp`, {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email }),
        })
      ).json();

      const formData = {
        firstName,
        secondName,
        email,
        phone,
        password,
        country,
        gender,
      };

      const data = { formData, otpCode };

      setLocalData("regData", data);

      navigate("/otp")
    } catch (error) {
      console.log(error);
      setMessage(error);
      setColor("danger");
      setShowAlert(true);
      setIsLoading(false);
    }
  };

  return (
    <div className="">
      <FormsNavbar />

      {showAlert ? (
        <Notification
          message={message}
          color={color}
          setShowAlert={setShowAlert}
        />
      ) : (
        ""
      )}

      <Container className="signupWrapper">
        <div className="signUp">
          <h3 id="formTitle">Register</h3>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">First name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter first name"
                    onInput={(e) => setFirstName(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Second name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter second name"
                    onInput={(e) => setSecondName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                {/* ---------- Email -------- */}
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Email</Form.Label>
                  <Form.Control
                    required
                    type="email"
                    placeholder="Enter email"
                    onInput={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                {/* ------- Gender ---------- */}
                <Form.Label id="formLabel">Gender</Form.Label>
                <Form.Group onChange={(e) => setGender(e.target.value)}>
                  <div key={`inline-radio`} className="mb-3">
                    <Form.Check
                      required
                      inline
                      value="male"
                      name="gender"
                      label="Male"
                      type="radio"
                      id={`inline-radio-1`}
                    />
                    <Form.Check
                      required
                      inline
                      value="female"
                      name="gender"
                      label="Female"
                      type="radio"
                      id={`inline-radio-2`}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>

            {/* ------------ Country -------------------- */}
            <Form.Group className="mb-3">
              <Form.Label id="formLabel">Country</Form.Label>
              <Form.Select required onChange={handleCountrySelection}>
                <option data-code="-SC-">--select country--</option>
                <option value="Afghanistan" data-country="+93" data-code="AF">
                  Afghanistan
                </option>
                <option
                  value="Åland Islands"
                  data-country="+358"
                  data-code="AX"
                >
                  Åland Islands
                </option>
                <option value="Albania" data-country="+355" data-code="AL">
                  Albania
                </option>
                <option value="Algeria" data-country="+213" data-code="DZ">
                  Algeria
                </option>
                <option
                  value="American Samoa"
                  data-country="+1684"
                  data-code="AS"
                >
                  American Samoa
                </option>
                <option value="Andorra" data-country="+376" data-code="AD">
                  Andorra
                </option>
                <option value="Angola" data-country="+244" data-code="AO">
                  Angola
                </option>
                <option value="Anguilla" data-country="+1264" data-code="AI">
                  Anguilla
                </option>
                <option value="Antarctica" data-country="+672" data-code="AQ">
                  Antarctica
                </option>
                <option
                  value="Antigua and Barbuda"
                  data-country="+1268"
                  data-code="AG"
                >
                  Antigua and Barbuda
                </option>
                <option value="Argentina" data-country="+54" data-code="AR">
                  Argentina
                </option>
                <option value="Armenia" data-country="+374" data-code="AM">
                  Armenia
                </option>
                <option value="Aruba" data-country="+297" data-code="AW">
                  Aruba
                </option>
                <option value="Australia" data-country="+61" data-code="AU">
                  Australia
                </option>
                <option value="Austria" data-country="+43" data-code="AT">
                  Austria
                </option>
                <option value="Azerbaijan" data-country="+994" data-code="AZ">
                  Azerbaijan
                </option>
                <option value="Bahamas" data-country="+1242" data-code="BS">
                  Bahamas
                </option>
                <option value="Bahrain" data-country="+973" data-code="BH">
                  Bahrain
                </option>
                <option value="Bangladesh" data-country="+880" data-code="BD">
                  Bangladesh
                </option>
                <option value="Barbados" data-country="+1246" data-code="BB">
                  Barbados
                </option>
                <option value="Belarus" data-country="+375" data-code="BY">
                  Belarus
                </option>
                <option value="Belgium" data-country="+32" data-code="BE">
                  Belgium
                </option>
                <option value="Belize" data-country="+501" data-code="BZ">
                  Belize
                </option>
                <option value="Benin" data-country="+229" data-code="BJ">
                  Benin
                </option>
                <option value="Bermuda" data-country="+1441" data-code="BM">
                  Bermuda
                </option>
                <option value="Bhutan" data-country="+975" data-code="BT">
                  Bhutan
                </option>
                <option
                  value="Bolivia, Plurinational State of bolivia"
                  data-country="+591"
                  data-code="BO"
                >
                  Bolivia, Plurinational State of bolivia
                </option>
                <option
                  value="Bosnia and Herzegovina"
                  data-country="+387"
                  data-code="BA"
                >
                  Bosnia and Herzegovina
                </option>
                <option value="Botswana" data-country="+267" data-code="BW">
                  Botswana
                </option>
                <option value="Bouvet Island" data-country="+47" data-code="BV">
                  Bouvet Island
                </option>
                <option value="Brazil" data-country="+55" data-code="BR">
                  Brazil
                </option>
                <option
                  value="British Indian Ocean Territory"
                  data-country="+246"
                  data-code="IO"
                >
                  British Indian Ocean Territory
                </option>
                <option
                  value="Brunei Darussalam"
                  data-country="+673"
                  data-code="BN"
                >
                  Brunei Darussalam
                </option>
                <option value="Bulgaria" data-country="+359" data-code="BG">
                  Bulgaria
                </option>
                <option value="Burkina Faso" data-country="+226" data-code="BF">
                  Burkina Faso
                </option>
                <option value="Burundi" data-country="+257" data-code="BI">
                  Burundi
                </option>
                <option value="Cambodia" data-country="+855" data-code="KH">
                  Cambodia
                </option>
                <option value="Cameroon" data-country="+237" data-code="CM">
                  Cameroon
                </option>
                <option value="Canada" data-country="+1" data-code="CA">
                  Canada
                </option>
                <option value="Cape Verde" data-country="+238" data-code="CV">
                  Cape Verde
                </option>
                <option
                  value="Cayman Islands"
                  data-country="+ 345"
                  data-code="KY"
                >
                  Cayman Islands
                </option>
                <option
                  value="Central African Republic"
                  data-country="+236"
                  data-code="CF"
                >
                  Central African Republic
                </option>
                <option value="Chad" data-country="+235" data-code="TD">
                  Chad
                </option>
                <option value="Chile" data-country="+56" data-code="CL">
                  Chile
                </option>
                <option value="China" data-country="+86" data-code="CN">
                  China
                </option>
                <option
                  value="Christmas Island"
                  data-country="+61"
                  data-code="CX"
                >
                  Christmas Island
                </option>
                <option
                  value="Cocos (Keeling) Islands"
                  data-country="+61"
                  data-code="CC"
                >
                  Cocos (Keeling) Islands
                </option>
                <option value="Colombia" data-country="+57" data-code="CO">
                  Colombia
                </option>
                <option value="Comoros" data-country="+269" data-code="KM">
                  Comoros
                </option>
                <option value="Congo" data-country="+242" data-code="CG">
                  Congo
                </option>
                <option
                  value="Congo, The Democratic Republic of the Congo"
                  data-country="+243"
                  data-code="CD"
                >
                  Congo, The Democratic Republic of the Congo
                </option>
                <option value="Cook Islands" data-country="+682" data-code="CK">
                  Cook Islands
                </option>
                <option value="Costa Rica" data-country="+506" data-code="CR">
                  Costa Rica
                </option>
                <option
                  value="Cote d'Ivoire"
                  data-country="+225"
                  data-code="CI"
                >
                  Cote d'Ivoire
                </option>
                <option value="Croatia" data-country="+385" data-code="HR">
                  Croatia
                </option>
                <option value="Cuba" data-country="+53" data-code="CU">
                  Cuba
                </option>
                <option value="Cyprus" data-country="+357" data-code="CY">
                  Cyprus
                </option>
                <option
                  value="Czech Republic"
                  data-country="+420"
                  data-code="CZ"
                >
                  Czech Republic
                </option>
                <option value="Denmark" data-country="+45" data-code="DK">
                  Denmark
                </option>
                <option value="Djibouti" data-country="+253" data-code="DJ">
                  Djibouti
                </option>
                <option value="Dominica" data-country="+1767" data-code="DM">
                  Dominica
                </option>
                <option
                  value="Dominican Republic"
                  data-country="+1849"
                  data-code="DO"
                >
                  Dominican Republic
                </option>
                <option value="Ecuador" data-country="+593" data-code="EC">
                  Ecuador
                </option>
                <option value="Egypt" data-country="+20" data-code="EG">
                  Egypt
                </option>
                <option value="El Salvador" data-country="+503" data-code="SV">
                  El Salvador
                </option>
                <option
                  value="Equatorial Guinea"
                  data-country="+240"
                  data-code="GQ"
                >
                  Equatorial Guinea
                </option>
                <option value="Eritrea" data-country="+291" data-code="ER">
                  Eritrea
                </option>
                <option value="Estonia" data-country="+372" data-code="EE">
                  Estonia
                </option>
                <option value="Ethiopia" data-country="+251" data-code="ET">
                  Ethiopia
                </option>
                <option
                  value="Falkland Islands (Malvinas)"
                  data-country="+500"
                  data-code="FK"
                >
                  Falkland Islands (Malvinas)
                </option>
                <option
                  value="Faroe Islands"
                  data-country="+298"
                  data-code="FO"
                >
                  Faroe Islands
                </option>
                <option value="Fiji" data-country="+679" data-code="FJ">
                  Fiji
                </option>
                <option value="Finland" data-country="+358" data-code="FI">
                  Finland
                </option>
                <option value="France" data-country="+33" data-code="FR">
                  France
                </option>
                <option
                  value="French Guiana"
                  data-country="+594"
                  data-code="GF"
                >
                  French Guiana
                </option>
                <option
                  value="French Polynesia"
                  data-country="+689"
                  data-code="PF"
                >
                  French Polynesia
                </option>
                <option
                  value="French Southern Territories"
                  data-country="+262"
                  data-code="TF"
                >
                  French Southern Territories
                </option>
                <option value="Gabon" data-country="+241" data-code="GA">
                  Gabon
                </option>
                <option value="Gambia" data-country="+220" data-code="GM">
                  Gambia
                </option>
                <option value="Georgia" data-country="+995" data-code="GE">
                  Georgia
                </option>
                <option value="Germany" data-country="+49" data-code="DE">
                  Germany
                </option>
                <option value="Ghana" data-country="+233" data-code="GH">
                  Ghana
                </option>
                <option value="Gibraltar" data-country="+350" data-code="GI">
                  Gibraltar
                </option>
                <option value="Greece" data-country="+30" data-code="GR">
                  Greece
                </option>
                <option value="Greenland" data-country="+299" data-code="GL">
                  Greenland
                </option>
                <option value="Grenada" data-country="+1473" data-code="GD">
                  Grenada
                </option>
                <option value="Guadeloupe" data-country="+590" data-code="GP">
                  Guadeloupe
                </option>
                <option value="Guam" data-country="+1671" data-code="GU">
                  Guam
                </option>
                <option value="Guatemala" data-country="+502" data-code="GT">
                  Guatemala
                </option>
                <option value="Guernsey" data-country="+44" data-code="GG">
                  Guernsey
                </option>
                <option value="Guinea" data-country="+224" data-code="GN">
                  Guinea
                </option>
                <option
                  value="Guinea-Bissau"
                  data-country="+245"
                  data-code="GW"
                >
                  Guinea-Bissau
                </option>
                <option value="Guyana" data-country="+592" data-code="GY">
                  Guyana
                </option>
                <option value="Haiti" data-country="+509" data-code="HT">
                  Haiti
                </option>
                <option
                  value="Heard Island and Mcdonald Islands"
                  data-country="+0"
                  data-code="HM"
                >
                  Heard Island and Mcdonald Islands
                </option>
                <option
                  value="Holy See (Vatican City State)"
                  data-country="+379"
                  data-code="VA"
                >
                  Holy See (Vatican City State)
                </option>
                <option value="Honduras" data-country="+504" data-code="HN">
                  Honduras
                </option>
                <option value="Hong Kong" data-country="+852" data-code="HK">
                  Hong Kong
                </option>
                <option value="Hungary" data-country="+36" data-code="HU">
                  Hungary
                </option>
                <option value="Iceland" data-country="+354" data-code="IS">
                  Iceland
                </option>
                <option value="India" data-country="+91" data-code="IN">
                  India
                </option>
                <option value="Indonesia" data-country="+62" data-code="ID">
                  Indonesia
                </option>
                <option
                  value="Iran, Islamic Republic of Persian Gulf"
                  data-country="+98"
                  data-code="IR"
                >
                  Iran, Islamic Republic of Persian Gulf
                </option>
                <option value="Iraq" data-country="+964" data-code="IQ">
                  Iraq
                </option>
                <option value="Ireland" data-country="+353" data-code="IE">
                  Ireland
                </option>
                <option value="Isle of Man" data-country="+44" data-code="IM">
                  Isle of Man
                </option>
                <option value="Israel" data-country="+972" data-code="IL">
                  Israel
                </option>
                <option value="Italy" data-country="+39" data-code="IT">
                  Italy
                </option>
                <option value="Jamaica" data-country="+1876" data-code="JM">
                  Jamaica
                </option>
                <option value="Japan" data-country="+81" data-code="JP">
                  Japan
                </option>
                <option value="Jersey" data-country="+44" data-code="JE">
                  Jersey
                </option>
                <option value="Jordan" data-country="+962" data-code="JO">
                  Jordan
                </option>
                <option value="Kazakhstan" data-country="+7" data-code="KZ">
                  Kazakhstan
                </option>
                <option value="Kenya" data-country="+254" data-code="KE">
                  Kenya
                </option>
                <option value="Kiribati" data-country="+686" data-code="KI">
                  Kiribati
                </option>
                <option
                  value="Korea, Democratic People's Republic of Korea"
                  data-country="+850"
                  data-code="KP"
                >
                  Korea, Democratic People's Republic of Korea
                </option>
                <option
                  value="Korea, Republic of South Korea"
                  data-country="+82"
                  data-code="KR"
                >
                  Korea, Republic of South Korea
                </option>
                <option value="Kosovo" data-country="+383" data-code="XK">
                  Kosovo
                </option>
                <option value="Kuwait" data-country="+965" data-code="KW">
                  Kuwait
                </option>
                <option value="Kyrgyzstan" data-country="+996" data-code="KG">
                  Kyrgyzstan
                </option>
                <option value="Laos" data-country="+856" data-code="LA">
                  Laos
                </option>
                <option value="Latvia" data-country="+371" data-code="LV">
                  Latvia
                </option>
                <option value="Lebanon" data-country="+961" data-code="LB">
                  Lebanon
                </option>
                <option value="Lesotho" data-country="+266" data-code="LS">
                  Lesotho
                </option>
                <option value="Liberia" data-country="+231" data-code="LR">
                  Liberia
                </option>
                <option
                  value="Libyan Arab Jamahiriya"
                  data-country="+218"
                  data-code="LY"
                >
                  Libyan Arab Jamahiriya
                </option>
                <option
                  value="Liechtenstein"
                  data-country="+423"
                  data-code="LI"
                >
                  Liechtenstein
                </option>
                <option value="Lithuania" data-country="+370" data-code="LT">
                  Lithuania
                </option>
                <option value="Luxembourg" data-country="+352" data-code="LU">
                  Luxembourg
                </option>
                <option value="Macao" data-country="+853" data-code="MO">
                  Macao
                </option>
                <option value="Macedonia" data-country="+389" data-code="MK">
                  Macedonia
                </option>
                <option value="Madagascar" data-country="+261" data-code="MG">
                  Madagascar
                </option>
                <option value="Malawi" data-country="+265" data-code="MW">
                  Malawi
                </option>
                <option value="Malaysia" data-country="+60" data-code="MY">
                  Malaysia
                </option>
                <option value="Maldives" data-country="+960" data-code="MV">
                  Maldives
                </option>
                <option value="Mali" data-country="+223" data-code="ML">
                  Mali
                </option>
                <option value="Malta" data-country="+356" data-code="MT">
                  Malta
                </option>
                <option
                  value="Marshall Islands"
                  data-country="+692"
                  data-code="MH"
                >
                  Marshall Islands
                </option>
                <option value="Martinique" data-country="+596" data-code="MQ">
                  Martinique
                </option>
                <option value="Mauritania" data-country="+222" data-code="MR">
                  Mauritania
                </option>
                <option value="Mauritius" data-country="+230" data-code="MU">
                  Mauritius
                </option>
                <option value="Mayotte" data-country="+262" data-code="YT">
                  Mayotte
                </option>
                <option value="Mexico" data-country="+52" data-code="MX">
                  Mexico
                </option>
                <option
                  value="Micronesia, Federated States of Micronesia"
                  data-country="+691"
                  data-code="FM"
                >
                  Micronesia, Federated States of Micronesia
                </option>
                <option value="Moldova" data-country="+373" data-code="MD">
                  Moldova
                </option>
                <option value="Monaco" data-country="+377" data-code="MC">
                  Monaco
                </option>
                <option value="Mongolia" data-country="+976" data-code="MN">
                  Mongolia
                </option>
                <option value="Montenegro" data-country="+382" data-code="ME">
                  Montenegro
                </option>
                <option value="Montserrat" data-country="+1664" data-code="MS">
                  Montserrat
                </option>
                <option value="Morocco" data-country="+212" data-code="MA">
                  Morocco
                </option>
                <option value="Mozambique" data-country="+258" data-code="MZ">
                  Mozambique
                </option>
                <option value="Myanmar" data-country="+95" data-code="MM">
                  Myanmar
                </option>
                <option value="Namibia" data-country="+264" data-code="NA">
                  Namibia
                </option>
                <option value="Nauru" data-country="+674" data-code="NR">
                  Nauru
                </option>
                <option value="Nepal" data-country="+977" data-code="NP">
                  Nepal
                </option>
                <option value="Netherlands" data-country="+31" data-code="NL">
                  Netherlands
                </option>
                <option
                  value="Netherlands Antilles"
                  data-country="+599"
                  data-code="AN"
                >
                  Netherlands Antilles
                </option>
                <option
                  value="New Caledonia"
                  data-country="+687"
                  data-code="NC"
                >
                  New Caledonia
                </option>
                <option value="New Zealand" data-country="+64" data-code="NZ">
                  New Zealand
                </option>
                <option value="Nicaragua" data-country="+505" data-code="NI">
                  Nicaragua
                </option>
                <option value="Niger" data-country="+227" data-code="NE">
                  Niger
                </option>
                <option value="Nigeria" data-country="+234" data-code="NG">
                  Nigeria
                </option>
                <option value="Niue" data-country="+683" data-code="NU">
                  Niue
                </option>
                <option
                  value="Norfolk Island"
                  data-country="+672"
                  data-code="NF"
                >
                  Norfolk Island
                </option>
                <option
                  value="Northern Mariana Islands"
                  data-country="+1670"
                  data-code="MP"
                >
                  Northern Mariana Islands
                </option>
                <option value="Norway" data-country="+47" data-code="NO">
                  Norway
                </option>
                <option value="Oman" data-country="+968" data-code="OM">
                  Oman
                </option>
                <option value="Pakistan" data-country="+92" data-code="PK">
                  Pakistan
                </option>
                <option value="Palau" data-country="+680" data-code="PW">
                  Palau
                </option>
                <option
                  value="Palestinian Territory, Occupied"
                  data-country="+970"
                  data-code="PS"
                >
                  Palestinian Territory, Occupied
                </option>
                <option value="Panama" data-country="+507" data-code="PA">
                  Panama
                </option>
                <option
                  value="Papua New Guinea"
                  data-country="+675"
                  data-code="PG"
                >
                  Papua New Guinea
                </option>
                <option value="Paraguay" data-country="+595" data-code="PY">
                  Paraguay
                </option>
                <option value="Peru" data-country="+51" data-code="PE">
                  Peru
                </option>
                <option value="Philippines" data-country="+63" data-code="PH">
                  Philippines
                </option>
                <option value="Pitcairn" data-country="+64" data-code="PN">
                  Pitcairn
                </option>
                <option value="Poland" data-country="+48" data-code="PL">
                  Poland
                </option>
                <option value="Portugal" data-country="+351" data-code="PT">
                  Portugal
                </option>
                <option value="Puerto Rico" data-country="+1939" data-code="PR">
                  Puerto Rico
                </option>
                <option value="Qatar" data-country="+974" data-code="QA">
                  Qatar
                </option>
                <option value="Romania" data-country="+40" data-code="RO">
                  Romania
                </option>
                <option value="Russia" data-country="+7" data-code="RU">
                  Russia
                </option>
                <option value="Rwanda" data-country="+250" data-code="RW">
                  Rwanda
                </option>
                <option value="Reunion" data-country="+262" data-code="RE">
                  Reunion
                </option>
                <option
                  value="Saint Barthelemy"
                  data-country="+590"
                  data-code="BL"
                >
                  Saint Barthelemy
                </option>
                <option
                  value="Saint Helena, Ascension and Tristan Da Cunha"
                  data-country="+290"
                  data-code="SH"
                >
                  Saint Helena, Ascension and Tristan Da Cunha
                </option>
                <option
                  value="Saint Kitts and Nevis"
                  data-country="+1869"
                  data-code="KN"
                >
                  Saint Kitts and Nevis
                </option>
                <option value="Saint Lucia" data-country="+1758" data-code="LC">
                  Saint Lucia
                </option>
                <option value="Saint Martin" data-country="+590" data-code="MF">
                  Saint Martin
                </option>
                <option
                  value="Saint Pierre and Miquelon"
                  data-country="+508"
                  data-code="PM"
                >
                  Saint Pierre and Miquelon
                </option>
                <option
                  value="Saint Vincent and the Grenadines"
                  data-country="+1784"
                  data-code="VC"
                >
                  Saint Vincent and the Grenadines
                </option>
                <option value="Samoa" data-country="+685" data-code="WS">
                  Samoa
                </option>
                <option value="San Marino" data-country="+378" data-code="SM">
                  San Marino
                </option>
                <option
                  value="Sao Tome and Principe"
                  data-country="+239"
                  data-code="ST"
                >
                  Sao Tome and Principe
                </option>
                <option value="Saudi Arabia" data-country="+966" data-code="SA">
                  Saudi Arabia
                </option>
                <option value="Senegal" data-country="+221" data-code="SN">
                  Senegal
                </option>
                <option value="Serbia" data-country="+381" data-code="RS">
                  Serbia
                </option>
                <option value="Seychelles" data-country="+248" data-code="SC">
                  Seychelles
                </option>
                <option value="Sierra Leone" data-country="+232" data-code="SL">
                  Sierra Leone
                </option>
                <option value="Singapore" data-country="+65" data-code="SG">
                  Singapore
                </option>
                <option value="Slovakia" data-country="+421" data-code="SK">
                  Slovakia
                </option>
                <option value="Slovenia" data-country="+386" data-code="SI">
                  Slovenia
                </option>
                <option
                  value="Solomon Islands"
                  data-country="+677"
                  data-code="SB"
                >
                  Solomon Islands
                </option>
                <option value="Somalia" data-country="+252" data-code="SO">
                  Somalia
                </option>
                <option value="South Africa" data-country="+27" data-code="ZA">
                  South Africa
                </option>
                <option value="South Sudan" data-country="+211" data-code="SS">
                  South Sudan
                </option>
                <option
                  value="South Georgia and the South Sandwich Islands"
                  data-country="+500"
                  data-code="GS"
                >
                  South Georgia and the South Sandwich Islands
                </option>
                <option value="Spain" data-country="+34" data-code="ES">
                  Spain
                </option>
                <option value="Sri Lanka" data-country="+94" data-code="LK">
                  Sri Lanka
                </option>
                <option value="Sudan" data-country="+249" data-code="SD">
                  Sudan
                </option>
                <option value="Suriname" data-country="+597" data-code="SR">
                  Suriname
                </option>
                <option
                  value="Svalbard and Jan Mayen"
                  data-country="+47"
                  data-code="SJ"
                >
                  Svalbard and Jan Mayen
                </option>
                <option value="Swaziland" data-country="+268" data-code="SZ">
                  Swaziland
                </option>
                <option value="Sweden" data-country="+46" data-code="SE">
                  Sweden
                </option>
                <option value="Switzerland" data-country="+41" data-code="CH">
                  Switzerland
                </option>
                <option
                  value="Syrian Arab Republic"
                  data-country="+963"
                  data-code="SY"
                >
                  Syrian Arab Republic
                </option>
                <option value="Taiwan" data-country="+886" data-code="TW">
                  Taiwan
                </option>
                <option value="Tajikistan" data-country="+992" data-code="TJ">
                  Tajikistan
                </option>
                <option
                  value="Tanzania, United Republic of Tanzania"
                  data-country="+255"
                  data-code="TZ"
                >
                  Tanzania, United Republic of Tanzania
                </option>
                <option value="Thailand" data-country="+66" data-code="TH">
                  Thailand
                </option>
                <option value="Timor-Leste" data-country="+670" data-code="TL">
                  Timor-Leste
                </option>
                <option value="Togo" data-country="+228" data-code="TG">
                  Togo
                </option>
                <option value="Tokelau" data-country="+690" data-code="TK">
                  Tokelau
                </option>
                <option value="Tonga" data-country="+676" data-code="TO">
                  Tonga
                </option>
                <option
                  value="Trinidad and Tobago"
                  data-country="+1868"
                  data-code="TT"
                >
                  Trinidad and Tobago
                </option>
                <option value="Tunisia" data-country="+216" data-code="TN">
                  Tunisia
                </option>
                <option value="Turkey" data-country="+90" data-code="TR">
                  Turkey
                </option>
                <option value="Turkmenistan" data-country="+993" data-code="TM">
                  Turkmenistan
                </option>
                <option
                  value="Turks and Caicos Islands"
                  data-country="+1649"
                  data-code="TC"
                >
                  Turks and Caicos Islands
                </option>
                <option value="Tuvalu" data-country="+688" data-code="TV">
                  Tuvalu
                </option>
                <option value="Uganda" data-country="+256" data-code="UG">
                  Uganda
                </option>
                <option value="Ukraine" data-country="+380" data-code="UA">
                  Ukraine
                </option>
                <option
                  value="United Arab Emirates"
                  data-country="+971"
                  data-code="AE"
                >
                  United Arab Emirates
                </option>
                <option
                  value="United Kingdom"
                  data-country="+44"
                  data-code="GB"
                >
                  United Kingdom
                </option>
                <option value="United States" data-country="+1" data-code="US">
                  United States
                </option>
                <option value="Uruguay" data-country="+598" data-code="UY">
                  Uruguay
                </option>
                <option value="Uzbekistan" data-country="+998" data-code="UZ">
                  Uzbekistan
                </option>
                <option value="Vanuatu" data-country="+678" data-code="VU">
                  Vanuatu
                </option>
                <option
                  value="Venezuela, Bolivarian Republic of Venezuela"
                  data-country="+58"
                  data-code="VE"
                >
                  Venezuela, Bolivarian Republic of Venezuela
                </option>
                <option value="Vietnam" data-country="+84" data-code="VN">
                  Vietnam
                </option>
                <option
                  value="Virgin Islands, British"
                  data-country="+1284"
                  data-code="VG"
                >
                  Virgin Islands, British
                </option>
                <option
                  value="Virgin Islands, U.S."
                  data-country="+1340"
                  data-code="VI"
                >
                  Virgin Islands, U.S.
                </option>
                <option
                  value="Wallis and Futuna"
                  data-country="+681"
                  data-code="WF"
                >
                  Wallis and Futuna
                </option>
                <option value="Yemen" data-country="+967" data-code="YE">
                  Yemen
                </option>
                <option value="Zambia" data-country="+260" data-code="ZM">
                  Zambia
                </option>
                <option value="Zimbabwe" data-country="+263" data-code="ZW">
                  Zimbabwe
                </option>
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label id="formLabel">Phone number</Form.Label>
              <Form.Control
                required
                type="number"
                placeholder="Enter Phone number"
                onInput={(e) => setPhone(e.target.value)}
              />
            </Form.Group>

            {/* ------- Password ------------- */}
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label id="formLabel">Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Enter Password"
                onInput={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label id="formLabel">Confirm Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Confirm Password"
                onInput={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>

            {/* ------- Captcha ------------- */}
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label id="formLabel">Captcha</Form.Label>
              <div className="captchaBox">
                <div className="captchaContainer">
                  <span id="code1">{code[0]}</span>
                  <span id="code2">{code[1]}</span>
                  <span id="code3">{code[2]}</span>
                  <span id="code4">{code[3]}</span>
                  <span id="code5">{code[4]}</span>
                  <span id="code6">{code[5]}</span>
                </div>
              </div>
              <Form.Control
                required
                type="number"
                placeholder="Enter code"
                onInput={(e) => setCaptcha(e.target.value)}
              />
            </Form.Group>

            <button type="submit" id="logBtn" disabled={isLoading}>
              {isLoading ? <Loader /> : "Register"}
            </button>
          </Form>

          <span>Have an account? </span>
          <NavLink to="/login">Login</NavLink>
        </div>
      </Container>

      {/* <DynamicFooter /> */}
      {/* <Footer /> */}
    </div>
  );
};

export default SignUp;
