import { Container } from "react-bootstrap";
import FormsNavbar from "../components/FormsNavbar";

const PendingApprovalPage = () => {
  return (
    <>
      <FormsNavbar />

      <Container className="signupWrapper">
        <div className="profile">
          <div style={{textAlign: "center", color: "#212529"}}>
            <h2>Review Process</h2>
            <br />

            <p>
              Your account with Bitloom Chain is currently under
              review, our team will review your application, you
              will be notified once the account is active.
            </p>
          </div>
        </div>
      </Container>
    </>
  );
};

export default PendingApprovalPage;
