import { useState, useContext } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Footer from "../components/Footer";
import FormsNavbar from "../components/FormsNavbar";
import { useEffect } from "react";
import Loader from "../components/Loader";
import Notification from "../components/Notification";
import { useNavigate } from "react-router-dom";
import { dashboardContext } from "../App";
import { getLocalData, setLocalData } from "../services/auth";
import DynamicFooter from "../components/DynamicFooter";

const Login = () => {
  const { userData, setUserData } = useContext(dashboardContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState([]);
  const [captcha, setCaptcha] = useState("");
  const [message, setMessage] = useState("");
  const [color, setColor] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Dwight Finance Solutions | Login";
  }, []);

  useEffect(() => {
    const userData = JSON.parse(getLocalData("userData"));
    if (userData) return navigate("/dashboard");
  }, []);

  // ------------ Generate Captcha --------------------------
  const generateRandomNumber = () => {
    return Math.floor(Math.random() * 9);
  };

  useEffect(() => {
    const nums = [];

    for (let i = 0; i < 6; i++) {
      nums.push(generateRandomNumber());
    }
    setCode(nums);
  }, []);

  // --------------- LOGIN -------------------------
  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      if (code.join("") !== captcha) throw "Captcha not correct";

      const result = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/login", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email,
            password,
          }),
        })
      ).json();

      if (
        result.profile.status !== "blocked" &&
        result.profile.status !== "pending"
      ) {
        setUserData(result);
        setMessage("Login successful");
        setIsLoading(false);
        setShowAlert(true);
        setColor("success");

        const isPendingInvestment = result.transaction.some(
          ({ status }) => status === "pending"
        );

        setLocalData("userData", result);
        setLocalData("userProfile", result.profile);
        setLocalData("isPendingInvestment", isPendingInvestment);
        setLocalData("cryptoData", result.crypto);
        setLocalData("plans", result.plans);
        setLocalData("transactions", result?.transaction);

        if (
          result.profile.role === "admin" ||
          result.profile.role === "super"
        ) {
          navigate("/userlist");
        } else {
          navigate("/dashboard");
        }
      } else if (
        result.profile.status !== "blocked" &&
        result.profile.status === "pending"
      ) {
        navigate("/pending");
      } else {
        setMessage("Invalid credentials!");
        setMessage("Invalid credentials!");
        setColor("danger");
        setIsLoading(false);
        setShowAlert(true);
      }
    } catch (error) {
      setMessage("Invalid credentials!");
      setColor("danger");
      setShowAlert(true);
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="login">
      <FormsNavbar />

      {showAlert ? (
        <Notification
          message={message}
          color={color}
          setShowAlert={setShowAlert}
        />
      ) : (
        ""
      )}

      <Container className="formContainer">
        <div className="formHolder">
          <h3 id="formTitle">Login</h3>
          <Form onSubmit={handleLogin}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label id="formLabel">Email address</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter email"
                className="shadow-none"
                value={email}
                onInput={(e) => setEmail(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label id="formLabel">Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Password"
                className="shadow-none"
                value={password}
                onInput={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label id="formLabel">Captcha</Form.Label>
              <div className="captchaBox">
                <div className="captchaContainer">
                  <span id="code1">{code[0]}</span>
                  <span id="code2">{code[1]}</span>
                  <span id="code3">{code[2]}</span>
                  <span id="code4">{code[3]}</span>
                  <span id="code5">{code[4]}</span>
                  <span id="code6">{code[5]}</span>
                </div>
              </div>
              <Form.Control
                required
                type="number"
                placeholder="Enter code"
                className="shadow-none"
                value={captcha}
                onInput={(e) => setCaptcha(e.target.value)}
                autocomplete="off"
              />
            </Form.Group>

            <button type="submit" id="logBtn" disabled={isLoading}>
              {isLoading ? <Loader /> : "Login"}
            </button>
          </Form>

          <Row>
            <Col>
              <div style={{ fontSize: "14px" }}>
                <span>Don't have an account? </span>
                <NavLink to="/register">Signup</NavLink>
              </div>
            </Col>
            <Col>
              <div style={{ fontSize: "14px" }}>
                <span>Forgotten Password? </span>
                <NavLink to="/resetpassword">Reset now</NavLink>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      {/* <DynamicFooter /> */}

      {/* <Footer /> */}
    </div>
  );
};

export default Login;
