import { useEffect } from "react";

const useInactivity = (timeoutDuration = 3600000) => {
  useEffect(() => {
    let inactivityTimeout;

    const clearLocalStorage = () => {
      console.log("User inactive for 1 hour. Clearing local storage...");
      // logout();

      localStorage.removeItem("userData");
      localStorage.removeItem("newTransaction");
      localStorage.removeItem("userProfile");
      localStorage.removeItem("recentTransaction");
      localStorage.removeItem("cryptoData");
      localStorage.removeItem("isPendingInvestment");
      localStorage.removeItem("showState");
      localStorage.removeItem("plans");
      localStorage.removeItem("regData");

      window.location.href = "https://bitloomchain.com";
    };

    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(clearLocalStorage, timeoutDuration);
    };

    // List of events indicating activity
    const activityEvents = ["mousemove", "keydown", "click", "scroll"];

    // Attach event listeners
    activityEvents.forEach((event) =>
      window.addEventListener(event, resetInactivityTimer)
    );

    // Set the initial timeout
    resetInactivityTimer();

    // Cleanup function to remove event listeners and clear timeout
    return () => {
      clearTimeout(inactivityTimeout);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetInactivityTimer)
      );
    };
  }, [timeoutDuration]);
};

export default useInactivity;
