import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router";
import Footer from "../components/Footer";
import LoggedInNavbar from "../components/LoggedInNavbar";
import { getLocalData, setLocalData } from "../services/auth";
import Loader from "../components/Loader";
import FetchLoader from "../components/FetchLoader";
import DynamicFooter from "../components/DynamicFooter";
import moment from "moment";
import DarkLoader from "../components/DarkLoader";

const AdminTransactions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.data || "";
  const [userRole, setUserRole] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [userData, setUserData] = useState([]);
  const [totalWalletBalance, settotalWalletBalance] = useState(0);
  const [err, setErr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [investing, setInvesting] = useState(false);
  const [profiting, setProfiting] = useState(false);
  const [isUpdatingWithdrawable, setIsUpdatingWithdrawable] = useState(false);
  const [editing, setEditing] = useState(false);

  const [hasChanged, setHasChanged] = useState(false);

  const [transactionId, setTransactionId] = useState("");
  const [transactionEmail, setTransactionEmail] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const [valueToUpdate, setValueToUpdate] = useState("");
  const [nameOfAction, setNameOfAction] = useState("");
  const [transactionAmount, setTransactionAmount] = useState(0);

  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalWithdrawable, setTotalWithdrawable] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [loadAmount, setLoadAmount] = useState(null);

  // ------------ Modal state -------------------------
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActionModel, setShowActionModel] = useState(false);
  const [showInvestModal, setShowInvestModal] = useState(false);
  const [showProfitModal, setProfitModal] = useState(false);
  const [showWithdrawableModal, setShowWithdrawableModal] = useState(false);

  useEffect(() => {
    document.title = "Dwight Finance Solutions | Transactions";
  }, []);

  // -------------- Modal close --------------------------
  const handleDeleteClose = () => setShowDeleteModal(false);

  const handleCloseActionModel = () => {
    setShowActionModel(false);
    setErr("");
    setIsLoading(false);
  };

  const hideModal = () => {
    setShowModal(false);
    setShowInvestModal(false);
    setProfitModal(false);
    setShowWithdrawableModal(false);
    setErr("");
  };

  const openInvestmentModal = () => {
    setShowInvestModal(true);
  };

  const openProfitModal = () => {
    setProfitModal(true);
  };

  const openWithdrawableModal = () => {
    setShowWithdrawableModal(true);
  };

  // -------------- Check Current User Role to see if eligible to view the users page ------------
  useEffect(() => {
    const currentUser = JSON.parse(getLocalData("userData"));
    if (
      currentUser.profile.role === "super" ||
      currentUser.profile.role === "admin"
    ) {
      return setUserRole(currentUser.profile.role);
    }
    navigate("/dashboard");
  }, []);

  // -------- Get user from the server ----------------
  useEffect(() => {
    setIsFetching(true);
    const getAllUsers = async () => {
      const result = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/getusertransaction", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email }),
        })
      ).json();

      setTransactions(result.transaction);
      setUserData({ profile: result.profile });
      setIsFetching(false);
    };

    getAllUsers();
  }, []);

  // ----- ON CHANGED ----------------
  useEffect(() => {
    const getAllUsers = async () => {
      setIsFetching(true);
      try {
        const result = await (
          await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/getusertransaction", {
            method: "POST",

            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ email }),
          })
        ).json();

        setTransactions(result.transaction);
        setUserData({ profile: result.profile });
      } catch (error) {
        setErr("Failed to fetch transactions");
      }
      setIsFetching(false);
    };

    getAllUsers();
  }, [hasChanged]);

  // ============ Logic to grab neccessary data for manipulation of transactions =======================
  const handleDeleteData = (
    e,
    transactionEmail,
    transactionId,
    nameOfFunctionality
  ) => {
    setShowDeleteModal(true);
    setTransactionEmail(transactionEmail);
    setTransactionId(transactionId);
  };

  const handleGrabTransactionData = (
    e,
    transactionId,
    transactionEmail,
    transactionStatus,
    transactionType,
    amount
  ) => {
    setShowActionModel(true);

    const nameOfAction = e.target.name;
    const value = e.target.value;

    setTransactionId(transactionId);
    setTransactionEmail(transactionEmail);
    setValueToUpdate(value);
    setNameOfAction(nameOfAction);
    setTransactionStatus(transactionStatus);
    setTransactionType(transactionType);
    setTransactionAmount(amount);
  };

  // ==================== Transaction Delete Logic ===========================
  const handleDeleteTransaction = async (e, transactionId, userRole) => {
    e.preventDefault();
    setIsLoading(true);

    let yam = await (
      await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/deletetransaction", {
        method: "POST",

        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ transactionId }),
      })
    ).json();

    console.log(yam);

    setTransactionEmail("");
    setTransactionId("");
    window.location.href = "/usertransaction";
  };

  // ==================== Transaction Update Logic ===========================
  const action = async (
    e,
    nameOfAction,
    transactionId,
    transactionEmail,
    valueToUpdate,
    transactionStatus,
    transactionType,
    transactionAmount
  ) => {
    e.preventDefault();
    setIsLoading(true);

    console.log({
      transactionId,
      valueToUpdate,
      transactionEmail,
      transactionAmount,
    });

    try {
      let result;
      if (nameOfAction === "status") {
        result = await fetch(
          "https://bitloomchain-api.bitloomchain.com/api/v1/updatetransactionstatus",
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({
              transactionId,
              valueToUpdate,
              transactionEmail,
              transactionAmount,
            }),
          }
        );
      } else if (nameOfAction === "withdrawable") {
        result = await fetch(
          "https://bitloomchain-api.bitloomchain.com/api/v1/updatetransactionwithdrawable",
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ transactionId, valueToUpdate }),
          }
        );
      } else if (nameOfAction === "updateProfit") {
        result = await fetch(
          "https://bitloomchain-api.bitloomchain.com/api/v1/updatetransactionprofit",
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({ transactionId, valueToUpdate }),
          }
        );
      }

      const updatedTransaction = await result.json();

      // Update the transactions state locally with the updated transaction
      setShowActionModel(false);
      setHasChanged(!hasChanged); // Trigger a refetch
      setIsLoading(false);
      setTransactionId("");
      window.location.href = "/usertransaction";
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  // ==================== Transaction Delete Modal ===========================
  const updateModal = (transactionEmail, transactionId, userRole) => {
    return (
      <Modal show={showDeleteModal} onHide={handleDeleteClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                Are you sure you want to delete transaction for{" "}
                <span style={{ color: "rgb(144 80 0)" }}>
                  {transactionEmail}
                </span>{" "}
                ?
              </Form.Label>
            </Form.Group>
            <button
              style={{ backgroundColor: "red" }}
              id="actionBtn"
              type="submit"
              size="block"
              onClick={(e) =>
                handleDeleteTransaction(e, transactionId, userRole)
              }
            >
              {isLoading ? <Loader /> : "Delete"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  // ==================== Transaction Manipulation Modal ===========================
  const actionModal = (
    nameOfAction,
    transactionId,
    transactionEmail,
    valueToUpdate,
    transactionStatus,
    transactionType,
    transactionAmount
  ) => {
    return (
      <Modal show={showActionModel} onHide={handleCloseActionModel}>
        <Modal.Header closeButton>
          <Modal.Title>Update Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) =>
              action(
                e,
                nameOfAction,
                transactionId,
                transactionEmail,
                valueToUpdate,
                transactionStatus,
                transactionType,
                transactionAmount
              )
            }
          >
            <Form.Group className="mb-3">
              {nameOfAction === "updateProfit" ? (
                <Form.Group>
                  <Form.Label>
                    Enter new profit for{" "}
                    <span style={{ color: "#edb267" }}>{transactionEmail}</span>{" "}
                  </Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="Enter profit"
                    onChange={(e) => setValueToUpdate(e.target.value)}
                  />
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {err}
                  </span>
                </Form.Group>
              ) : (
                <Form.Label>
                  Are you sure you want to make an update on{" "}
                  <span style={{ color: "rgb(144 80 0)" }}>
                    {transactionEmail}
                  </span>{" "}
                  ?
                  <br />
                  <span
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {err}
                  </span>
                </Form.Label>
              )}
            </Form.Group>
            <button id="actionBtn" type="submit" variant="success" size="block">
              {isLoading ? <Loader /> : "Update"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  // ==================== Load Modal ===========================
  const loadModal = () => {
    return (
      <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Load Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleLoad}>
            <Form.Group className="mb-3">
              <Form.Label>
                This feature allows you to add a{" "}
                <strong style={{ color: "#625f5f" }}>
                  withdrawable amount
                </strong>{" "}
                directly to the client's account, without requiring the client
                to make an investment.
              </Form.Label>
              <Form.Label>
                {" "}
                <strong>Enter loading amount </strong>
              </Form.Label>

              <Form.Control
                required
                type="number"
                placeholder="Enter amount you wish to load CL account"
                onChange={(e) => setLoadAmount(e.target.value)}
              />
              <div style={{ height: "20px" }}>
                <span style={{ color: "red", fontSize: "13px" }}>{err}</span>
              </div>
            </Form.Group>

            <button
              className={`butt`}
              style={{
                width: "162px",
                height: "44px",
                marginTop: "40px",
                marginBottom: "10px",
              }}
            >
              {isLoading ? <Loader /> : "Load account"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  // ==================== Investment Modal ===========================
  const investmentModal = () => {
    return (
      <Modal show={showInvestModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Total Investment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                Enter the{" "}
                <strong style={{ color: "#625f5f" }}>
                  Total Investment Amount
                </strong>{" "}
                you wish to be displayed on the client's dashboard.
              </Form.Label>
              <Form.Label>
                {" "}
                <strong> Enter amount</strong>
              </Form.Label>

              <Form.Control
                required
                type="number"
                placeholder="Enter amount"
                onChange={(e) => setTotalInvestment(e.target.value)}
              />
              <div style={{ height: "20px" }}>
                <span style={{ color: "red", fontSize: "13px" }}>{err}</span>
              </div>
            </Form.Group>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <button
                  onClick={(e) => handleUpdateInvestment(e)}
                  className={`butt`}
                  style={{
                    width: "162px",
                    height: "44px",
                    marginTop: "40px",
                    marginBottom: "10px",
                  }}
                  disabled={investing}
                >
                  {investing ? <Loader /> : "Update"}
                </button>
              </div>

              <div>
                <button
                  onClick={(e) => editInvestment(e)}
                  className={`butt`}
                  style={{
                    width: "162px",
                    height: "44px",
                    marginTop: "40px",
                    marginBottom: "10px",
                    backgroundColor: "transparent",
                    color: "#035a0a",
                    border: "2px solid",
                  }}
                  disabled={editing}
                >
                  {editing ? <DarkLoader /> : "Edit"}
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  // ==================== Investment Modal ===========================
  const withdrwableAmountModal = () => {
    return (
      <Modal show={showWithdrawableModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Total Withdrawable</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => handleUpdateWithdrawable(e)}>
            <Form.Group className="mb-3">
              <Form.Label>
                Enter the{" "}
                <strong style={{ color: "#625f5f" }}>
                  Total Withdrawable Amount
                </strong>{" "}
                you wish to be displayed on the client's dashboard.
              </Form.Label>
              <Form.Label>
                {" "}
                <strong> Enter amount</strong>
              </Form.Label>

              <Form.Control
                required
                type="number"
                placeholder="Enter amount"
                onChange={(e) => setTotalWithdrawable(e.target.value)}
              />
              <div style={{ height: "20px" }}>
                <span style={{ color: "red", fontSize: "13px" }}>{err}</span>
              </div>
            </Form.Group>

            <button
              className={`butt`}
              style={{
                width: "162px",
                height: "44px",
                marginTop: "40px",
                marginBottom: "10px",
                backgroundColor: "rgb(100, 31, 216)",
              }}
              disabled={isUpdatingWithdrawable}
            >
              {isUpdatingWithdrawable ? <Loader /> : "Update"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  // ==================== Profit Modal ===========================
  const profirModal = () => {
    return (
      <Modal show={showProfitModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Total Profit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                Enter the{" "}
                <strong style={{ color: "#625f5f" }}>
                  Total Profit Amount
                </strong>{" "}
                you wish to be displayed on the client's dashboard.
              </Form.Label>
              <Form.Label>
                {" "}
                <strong> Enter profit</strong>
              </Form.Label>

              <Form.Control
                required
                type="number"
                placeholder="Enter profit"
                onChange={(e) => setTotalProfit(e.target.value)}
              />
              <div style={{ height: "20px" }}>
                <span style={{ color: "red", fontSize: "13px" }}>{err}</span>
              </div>
            </Form.Group>

            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <button
                  onClick={(e) => handleUpdateProfit(e)}
                  className={`butt`}
                  style={{
                    width: "162px",
                    height: "44px",
                    marginTop: "40px",
                    marginBottom: "10px",
                    backgroundColor: "rgb(220, 158, 17)",
                  }}
                  disabled={profiting}
                >
                  {profiting ? <Loader /> : "Update"}
                </button>
              </div>

              <div>
                <button
                  onClick={(e) => editProfit(e)}
                  className={`butt`}
                  style={{
                    width: "162px",
                    height: "44px",
                    marginTop: "40px",
                    marginBottom: "10px",
                    backgroundColor: "transparent",
                    color: "#a5784c",
                    border: "2px solid",
                  }}
                  disabled={editing}
                >
                  {editing ? <DarkLoader /> : "Edit"}
                </button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  const handleLoad = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/loadaccount", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email: userData.profile.email,
            loadAmount,
          }),
        })
      ).json();

      console.log(res);
      setUserData(res);

      setShowModal(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleUpdateInvestment = async (e) => {
    e.preventDefault();

    try {
      setInvesting(true);
      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/adJusttotalbalances", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email,
            amount: totalInvestment,
            type: "investment",
          }),
        })
      ).json();

      const result = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/getusertransaction", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email }),
        })
      ).json();

      setTransactions(result.transaction);
      setUserData({ profile: result.profile });
      setShowInvestModal(false);
      setTotalInvestment(0);
    } catch (error) {
      console.log(err);
    } finally {
      setInvesting(false);
    }
  };

  const editInvestment = async (e) => {
    e.preventDefault();

    try {
      setEditing(true);
      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/adJusttotalbalances", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email,
            amount: totalInvestment,
            type: "editInvestment",
          }),
        })
      ).json();

      const result = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/getusertransaction", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email }),
        })
      ).json();

      setTransactions(result.transaction);
      setUserData({ profile: result.profile });
      setShowInvestModal(false);
      setTotalInvestment(0);
    } catch (error) {
      console.log(err);
    } finally {
      setEditing(false);
    }
  };

  const handleUpdateProfit = async (e) => {
    e.preventDefault();

    try {
      setProfiting(true);
      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/adJusttotalbalances", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email,
            amount: totalProfit,
            type: "profit",
          }),
        })
      ).json();

      const result = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/getusertransaction", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email }),
        })
      ).json();

      setTransactions(result.transaction);
      setUserData({ profile: result.profile });
      setProfitModal(false);
      setTotalProfit(0);
    } catch (error) {
      console.log(err);
    } finally {
      setProfiting(false);
    }
  };

  const editProfit = async (e) => {
    e.preventDefault();

    try {
      setEditing(true);
      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/adJusttotalbalances", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email,
            amount: totalProfit,
            type: "editProfit",
          }),
        })
      ).json();

      const result = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/getusertransaction", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email }),
        })
      ).json();

      setTransactions(result.transaction);
      setUserData({ profile: result.profile });
      setProfitModal(false);
      setTotalProfit(0);
    } catch (error) {
      console.log(err);
    } finally {
      setEditing(false);
    }
  };

  const handleUpdateWithdrawable = async (e) => {
    e.preventDefault();

    try {
      setIsUpdatingWithdrawable(true);

      if (
        totalWithdrawable >
        userData?.profile.totalInvestment + userData?.profile.activeInvestment
      ) {
        throw new Error(
          "Withdrawable amount cannot be more than available balance"
        );
      }

      const res = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/adJusttotalbalances", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({
            email,
            amount: totalWithdrawable,
            type: "",
          }),
        })
      ).json();

      const result = await (
        await fetch("https://bitloomchain-api.bitloomchain.com/api/v1/getusertransaction", {
          method: "POST",

          headers: {
            "content-type": "application/json",
          },
          body: JSON.stringify({ email }),
        })
      ).json();

      setTransactions(result.transaction);
      setUserData({ profile: result.profile });
      setProfitModal(false);
      setTotalProfit(0);
      setShowWithdrawableModal(false);
    } catch (error) {
      console.log(err);
      setErr(error.message);
    } finally {
      setIsUpdatingWithdrawable(false);
    }
  };

  // console.log(typeof userData?.profile.totalInvestment);

  return (
    <>
      <LoggedInNavbar />
      {isFetching ? <FetchLoader /> : ""}
      {loadModal()}
      {updateModal(transactionEmail, transactionId, userRole)}
      {actionModal(
        nameOfAction,
        transactionId,
        transactionEmail,
        valueToUpdate,
        transactionStatus,
        transactionType,
        transactionAmount
      )}
      {investmentModal()}
      {profirModal()}
      {withdrwableAmountModal()}
      <Container>
        <Row>
          <Col className="col-xl-4 col-sm-12 mb-4">
            <div className="dashboardCard" style={{ boxShadow: "0 0" }}>
              <div className="dashboardCardContent">
                <span>Total Wallet Balance</span>
                <h3>{`$${
                  userData.profile
                    ? (
                        Number(userData?.profile.totalInvestment) +
                        Number(userData?.profile.activeInvestment) +
                        Number(userData?.profile.referralEarning) +
                        Number(userData?.profile.totalWalletBalance)
                      ).toLocaleString()
                    : 0
                }`}</h3>
              </div>

              {/* <img src={wallet} alt="icon" /> */}
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-12 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Investments</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.totalInvestment.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              {/* <img src={cubes} alt="icon" /> */}
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Investments Profit</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.activeInvestment.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              {/* <img src={dollar} alt="icon" /> */}
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="case">
              <div className="dashboardCard">
                <div className="dashboardCardContent">
                  <span>Available Withdrawal</span>
                  <h3>{`$${
                    userData.profile
                      ? userData.profile.availableWithdrawal.toLocaleString()
                      : 0
                  }`}</h3>
                </div>
                {/* <button className={`butt `} onClick={openModal}>
                  Load
                </button> */}
              </div>
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Total Withdrawal</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.totalWithdrawal.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              {/* <img src={withdraw} alt="icon" /> */}
            </div>
          </Col>

          <Col className="col-xl-4 col-sm-6 mb-4">
            <div className="dashboardCard">
              <div className="dashboardCardContent">
                <span>Referral Earning</span>
                <h3>{`$${
                  userData.profile
                    ? userData.profile.referralEarning.toLocaleString()
                    : 0
                }`}</h3>
              </div>

              {/* <img src={ref} alt="icon" /> */}
            </div>
          </Col>
        </Row>
      </Container>
      {/* ============ Buttons ============ */}
      <Container>
        <hr style={{ color: "gray" }} />
        <h5
          style={{
            color: "#595959",
            fontSize: "18px",
            marginTop: "20px",
          }}
        >
          Update Transaction
        </h5>
        <div
          className="StatusButtonWrapper mt-4"
          style={{ justifyContent: "space-between" }}
        >
          <button
            className="userStatusBtn"
            style={{
              backgroundColor: "rgb(12 77 58)",
              width: "127px",
              height: 50,
              borderRadius: 0,
            }}
            onClick={openInvestmentModal}
            // disabled={approving}
          >
            {/* {approving ? <Loader /> : "Approve"} */}
            Investment
          </button>
          <button
            className="userStatusBtn"
            style={{
              backgroundColor: "rgb(220 158 17)",
              width: "127px",
              height: 50,
              borderRadius: 0,
            }}
            // disabled={suspending}
            onClick={openProfitModal}
          >
            {/* {suspending ? <Loader /> : "Suspend"} */}
            Profit
          </button>

          <button
            className="userStatusBtn"
            style={{
              backgroundColor: "rgb(100 31 216)",
              width: "127px",
              height: 50,
              borderRadius: 0,
            }}
            onClick={openWithdrawableModal}
            // disabled={unsuspending}
          >
            {/* {unsuspending ? <Loader /> : "Unsuspend"} */}
            Withdrawable
          </button>
        </div>

        <hr style={{ color: "gray", marginTop: 40 }} />
      </Container>
      <br /> <br />
      {/* ------------- Transaction Table ----------------------- */}
      <Container fluid>
        <h3 id="aboutText1">All Transactions</h3>
        <Table size="sm" responsive>
          <tr>
            <th>S/N</th>
            <th>User</th>
            <th>Reference ID</th>
            <th>Type</th>
            <th>Details</th>
            <th>Amount</th>
            <th>Profit</th>
            <th>Status</th>
            <th>Activate</th>
            <th>Paid</th>
            <th>Reject</th>
            {/* <th>Withdrawable</th> */}
            <th>Created At</th>
            {/* <th>Withdrawal Requested</th> */}
            {/* <th>Update Profit</th> */}
            <th>Delete</th>
          </tr>

          <>
            {transactions &&
              transactions.map(
                (
                  {
                    email,
                    amount,
                    createdAt,
                    details,
                    profit,
                    referenceId,
                    requestedWithdrawal,
                    status,
                    type,
                    userId,
                    id,
                    withdrawalable,
                  },
                  index
                ) => (
                  <tr>
                    <td>{index + 1} </td>
                    <td>{email}</td>
                    <td id="referrenceID">{referenceId}</td>
                    <td>{type}</td>
                    <td>{details}</td>
                    <td id="plusAmount">{`$${amount}`}</td>
                    <td>{`$${profit}`}</td>

                    {/* ----------- Status --------------- */}

                    <td>{status}</td>

                    <td>
                      <Button
                        // variant="danger"
                        size="sm"
                        bg="danger"
                        id="deleteBtn"
                        style={{
                          backgroundColor: "rgb(24 69 127)",
                          border: "rgb(24 69 127)",
                        }}
                        name="status"
                        value={"active"}
                        onClick={(e) =>
                          handleGrabTransactionData(
                            e,
                            id,
                            email,
                            status,
                            type,
                            amount
                          )
                        }
                        disabled={
                          userRole && status !== "pending" ? true : false
                        }
                      >
                        Activate
                      </Button>
                    </td>

                    <td>
                      <Button
                        // variant="danger"
                        value={"paid"}
                        name="status"
                        size="sm"
                        bg="danger"
                        id="deleteBtn"
                        style={{
                          backgroundColor: "rgb(12, 77, 58)",
                          border: "rgb(12, 77, 58)",
                        }}
                        onClick={(e) =>
                          handleGrabTransactionData(
                            e,
                            id,
                            email,
                            status,
                            type,
                            amount
                          )
                        }
                        disabled={
                          userRole && status !== "requested" ? true : false
                        }
                      >
                        Pay
                      </Button>
                    </td>

                    <td>
                      <Button
                        // variant="danger"
                        value={"rejected"}
                        name="status"
                        size="sm"
                        bg="danger"
                        id="deleteBtn"
                        style={{
                          backgroundColor: "#6e0606",
                          border: "#6e0606",
                        }}
                        onClick={(e) =>
                          handleGrabTransactionData(
                            e,
                            id,
                            email,
                            status,
                            type,
                            amount
                          )
                        }
                        disabled={
                          userRole && status !== "requested" ? true : false
                        }
                      >
                        Decline
                      </Button>
                    </td>

                    <td> {moment.utc(createdAt.split("T")[0]).format("ll")}</td>

                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        bg="danger"
                        id="deleteBtn"
                        onClick={(e) => handleDeleteData(e, email, id)}
                        disabled={
                          userRole && userRole === "admin" ? true : false
                        }
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                )
              )}
          </>
        </Table>
      </Container>
    </>
  );
};

export default AdminTransactions;
