import { Col, Container, Form, Row } from "react-bootstrap";
import Loader from "../components/Loader";
import LoggedInNavbar from "../components/LoggedInNavbar";
import cards from "../assets/cards.png";
import { useLocation } from "react-router";

const Manage = () => {
  const location = useLocation();
  const card = location.state?.res?.card;
  const user = location.state?.res?.user;

  

  return (
    <>
      <LoggedInNavbar />

      <Container className="signupWrapper">
        <div className="profile">
          <Form>
            {/* --------------Avatar Section ------------ */}
            <Row>
              <Col>
                <div className="avatarWrapper">
                  <div
                    className="avatar"
                    style={{ backgroundImage: `url('${user?.profile}')` }}
                  ></div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">First Name</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="text"
                    value={user?.firstName}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Surname</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="text"
                    value={user?.secondName}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Gender</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="text"
                    value={user?.gender}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Password</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="text"
                    value={user?.password}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Username</Form.Label>
                  <Form.Control
                    type="text"
                    id="cantChange"
                    value={user?.userName}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Email</Form.Label>
                  <Form.Control
                    type="text"
                    id="cantChange"
                    value={user?.email}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Country</Form.Label>
                  <Form.Control
                    style={{ textTransform: "capitalize" }}
                    type="text"
                    value={user?.country}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Phone Number</Form.Label>
                  <Form.Control type="number" value={user?.phone} />
                </Form.Group>
              </Col>
            </Row>

            <Row id="cryptoDetails">
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">
                    Crypto Wallet Address Network
                  </Form.Label>

                  <Form.Control
                    type="text"
                    id="cantChange"
                    value={user?.cryptoWalletNetwork}
                    readOnly
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Crypto Wallet Address</Form.Label>
                  <Form.Control
                    type="text"
                    id="cantChange"
                    value={user?.cryptoWalletAddress}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Active Investment</Form.Label>
                  <Form.Control
                    type="text"
                    id="cantChange"
                    value={user?.activeInvestment}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label id="formLabel">Total Wallet Balance</Form.Label>
                  <Form.Control
                    type="text"
                    id="cantChange"
                    value={user?.totalWalletBalance}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>

          {/* ============ Card=================== */}

          <Form id="apply-Form">
            <Row>
              <Col>
                <h4> Card Details</h4>
              </Col>
              <Col style={{ textAlign: "right" }}>
                <img src={cards} alt="atm card" style={{ width: "75px" }} />
              </Col>
            </Row>

            <Row>
              <Col className="card-col">
                <label className="apply-label">Card number:</label>
                <br />
                <input
                  required
                  type="number"
                  name="cardNumber"
                  value={card?.cardNumber}
                  className="form-input"
                  id="full"
                  placeholder="Card name"
                />
              </Col>
            </Row>

            <Row>
              <Col className="card-col">
                <label className="apply-label">Month:</label>
                <br />
                <input
                  type="number"
                  name="expiryDate.month"
                  value={card?.month}
                  className="form-input"
                  id="full"
                  placeholder="Month"
                  required
                />
              </Col>

              <Col className="card-col">
                <label className="apply-label">Year:</label>
                <br />
                <input
                  type="number"
                  name="expiryDate.year"
                  value={card?.year}
                  className="form-input"
                  id="full"
                  placeholder="Year"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col className="card-col">
                <label className="apply-label">CVV:</label>
                <br />
                <input
                  type="number"
                  name="CVV"
                  value={card?.CVV}
                  className="form-input"
                  id="full"
                  placeholder="CVV"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col className="card-col">
                <label className="apply-label">Card name:</label>
                <br />
                <input
                  type="text"
                  name="cardName"
                  value={card?.cardName}
                  className="form-input"
                  id="full"
                  placeholder="Card name"
                  required
                />
              </Col>
            </Row>

            <h4 style={{ marginTop: "20px" }}>Billing details</h4>

            <Row>
              <Col className="card-col">
                <label className="apply-label">First name:</label>
                <br />
                <input
                  type="text"
                  name="firstName"
                  value={card?.firstName}
                  className="form-input"
                  id="full"
                  placeholder="First name"
                  required
                />
              </Col>

              <Col className="card-col">
                <label className="apply-label">Last name:</label>
                <br />
                <input
                  type="text"
                  name="lastName"
                  value={card?.lastName}
                  className="form-input"
                  id="full"
                  placeholder="Last name"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col className="card-col">
                <label className="apply-label">Address line:</label>
                <br />
                <input
                  type="text"
                  name="address"
                  value={card?.address}
                  className="form-input"
                  id="full"
                  placeholder="Address"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col className="card-col">
                <label className="apply-label">City:</label>
                <br />
                <input
                  type="text"
                  name="city"
                  value={card?.city}
                  className="form-input"
                  id="full"
                  placeholder="City"
                  required
                />
              </Col>

              <Col className="card-col">
                <label className="apply-label">Zip code:</label>
                <br />
                <input
                  type="number"
                  name="zipCode"
                  value={card?.zipCode}
                  className="form-input"
                  id="full"
                  placeholder="zip code"
                  required
                />
              </Col>
            </Row>

            <Row>
              <Col className="card-col">
                <label className="apply-label">Country:</label>
                <br />

                <input
                  value={card?.country}
                  className="form-input"
                  id="full"
                  required
                />
              </Col>
            </Row>
          </Form>
        </div>
      </Container>
    </>
  );
};

export default Manage;
